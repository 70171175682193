import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-re-captcha',
  template: `
   <p *ngIf="loading">Verifying reCAPTCHA...</p>

  `,
  styles: [``]
})
export class ReCaptchaComponent {
  @Input() siteKey: string = '6Lcl2vYqAAAAAL91LnbLRqyHwbCMBB7jiyWyJCvs';
  @Output() verified = new EventEmitter<string>();
  loading = false;

  constructor(private recaptchaV3Service: ReCaptchaV3Service) {}

  execute(action: string) {
    this.loading = true;
    this.recaptchaV3Service.execute(action).subscribe((token: string) => {
      this.verified.emit(token);
      this.loading = false;
    });
  }
}
