import { Component, Input, Output,EventEmitter } from '@angular/core';
import { Menu } from '../../utils/menu';

@Component({
  selector: 'nav-dropdown',
  template: `
    <div class="nav-item dropdown">
      <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">{{
        title
      }}</a>
      <div class="dropdown-menu m-0">
        <a
          *ngFor="let menu of dropdownMenu" (click)="closeMenu()"
          [routerLink]="menu?.link"
          class="dropdown-item"
          >{{ menu?.name }}</a
        >
      </div>
    </div>
  `,
})
export class NavDropdownComponent {
  @Input()
  dropdownMenu!: Menu[];
  @Input('title') title!: string;

  @Output() closeBarMenu = new EventEmitter<void>();


  closeMenu(): void {
    this.closeBarMenu.emit();
  }
}
