<div class="container-fluid bg-primary py-5 bg-header" style="margin-bottom: 90px;">
    <div class="row py-5">
        <div class="col-12 pt-lg-5 mt-lg-5 text-center">
            <h1 class="display-4 text-white animated zoomIn">Subscription Services</h1>
            <a href="" class="h5 text-white">Home</a>
            <i class="far fa-circle text-white px-2"></i>
            <a href="" class="h5 text-white">Prices</a>
        </div>
    </div>
</div>

<div class="">
    <div class="container">
        <div class="row">
            <h5>We are the leaders in storm tracking and weather notifications for your town. Whether you are an executive, mom, golfer or a weather enthusiast we have the subscription service for you. Don't get caught in the cold this winter, subscribe to one of our services below.</h5>
        </div>
    </div>
</div>
  
  <!-- Pricing Plan Start -->
   <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container py-5">
        <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px;">
            <h5 class="fw-bold text-primary text-uppercase">Notification Services</h5>
        </div>
        <div class="row g-0">
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                <div class="bg-light rounded">
                    <div class="border-bottom py-4 px-5 mb-4">
                        <h4 class="text-primary mb-1">Free Plan</h4>
                    </div>
                    <div class="p-5 pt-0">
                        <h1 class="display-5 mb-3">
                            <small class="align-top" style="font-size: 22px; line-height: 45px;">R</small>00.00<small
                                class="align-bottom" style="font-size: 16px; line-height: 40px;">/ Month</small>
                        </h1>
                        <div class="d-flex justify-content-between mb-3"><span>Daily weather forecast E-mail</span><i class="fa fa-check text-primary pt-1"></i></div>
                        <hr>
                        <div class="d-flex justify-content-between mb-3"><span>Weekly weather forecast <strong>SMS</strong> <br />
                            Storm notifications sent 60min before storm hits <strong>SMS</strong> <br />
                            Unusual rain, wind & temperature notices <strong>SMS</strong></span><i class="fa fa-times text-danger pt-1"></i></div>
                       <hr>
                            <div class="d-flex justify-content-between mb-3"><span>Unusual weather notices for fog & frost <strong>SMS</strong> <br />
                            Hail notification for commute location <strong>SMS</strong> <br />
                            National weather notification <strong>SMS</strong> <br />
                            Daily weather <strong>SMS</strong> <br />
                            Sunrise & sunset times <strong>SMS</strong></span><i class="fa fa-times text-danger pt-1"></i></div>
                       
                    </div>
                </div>
            </div>
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                <div class="bg-white rounded shadow position-relative" style="z-index: 1;">
                    <div class="border-bottom py-4 px-5 mb-4">
                        <h4 class="text-primary mb-1">Starter Plan</h4>
                    </div>
                    <div class="p-5 pt-0">
                        <h1 class="display-5 mb-3">
                            <small class="align-top" style="font-size: 22px; line-height: 45px;">R</small>39.99<small
                                class="align-bottom" style="font-size: 16px; line-height: 40px;">/ Month</small>
                        </h1>
                        <div class="d-flex justify-content-between mb-3"><span>Daily weather forecast E-mail</span><i class="fa fa-check text-primary pt-1"></i></div>
                        <hr>
                        <div class="d-flex justify-content-between mb-3"><span>Weekly weather forecast <strong>SMS</strong> <br />
                            Storm notifications sent 60min before storm hits <strong>SMS</strong> <br />
                            Unusual rain, wind & temperature notices <strong>SMS</strong></span><i class="fa fa-check text-primary pt-1"></i></div>
                       <hr>
                            <div class="d-flex justify-content-between mb-3"><span>Unusual weather notices for fog & frost <strong>SMS</strong> <br />
                            Hail notification for commute location <strong>SMS</strong> <br />
                            National weather notification <strong>SMS</strong> <br />
                            Daily weather <strong>SMS</strong> <br />
                            Sunrise & sunset times <strong>SMS</strong></span><i class="fa fa-times text-danger pt-1"></i></div>
                      
                    </div>
                </div>
            </div>
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                <div class="bg-light rounded">
                    <div class="border-bottom py-4 px-5 mb-4">
                        <h4 class="text-primary mb-1">Enthusiast Plan</h4>
                    </div>
                    <div class="p-5 pt-0">
                        <h1 class="display-5 mb-3">
                            <small class="align-top" style="font-size: 22px; line-height: 45px;">R</small>179.99<small
                                class="align-bottom" style="font-size: 16px; line-height: 40px;">/ Month</small>
                        </h1>
                        <div class="d-flex justify-content-between mb-3"><span>Daily weather forecast E-mail</span><i class="fa fa-check text-primary pt-1"></i></div>
                       <hr>
                        <div class="d-flex justify-content-between mb-3"><span> Weekly weather forecast <strong>SMS</strong> <br />
                            Storm notifications sent 60min before storm hits <strong>SMS</strong> <br />
                            Unusual rain, wind & temperature notices <strong>SMS</strong></span><i class="fa fa-check text-primary pt-1"></i></div>
                       <hr>
                            <div class="d-flex justify-content-between mb-3"><span> Unusual weather notices for fog & frost <strong>SMS</strong> <br />
                            Hail notification for commute location <strong>SMS</strong> <br />
                            National weather notification <strong>SMS</strong> <br />
                            Daily weather <strong>SMS</strong> <br />
                            Sunrise & sunset times <strong>SMS</strong></span><i class="fa fa-check text-primary pt-1"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div style="text-align: center;margin-top:20px;font-size: 19px;">
    <a [href]="register_url" target="_blank" class="btn btn-primary">Click here to Register</a>	
</div>
  <!-- Pricing Plan Start -->
 <app-webserviceprice></app-webserviceprice>


