import { Component, OnInit } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { environment } from 'src/environments/environment';
import { isDevMode } from '@angular/core';
import { MainCardData } from 'src/app/shared/interfaces/cards/main-card.interface';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  constructor(private config: AppConfig){}

 register_url : string =this.config.AFRICAWEATHER_REGISTER_URL;
 portal_url:string = this.config.AFRICAWEATHER_PORTAL_WEB;

 ngOnInit(): void {
}

mainCardDataList: MainCardData[] = [
  {
    headerText: 'Transforming weather data into actionable insights',
    descriptionText: "At AfricaWeather, while we can't control the weather, we empower you to manage its impact and safeguard your business against its risks.",
    buttonText: 'Learn More',
    buttonLink: '/about-us',
    backgroundImage: 'assets/images/banner.jpg'
  },
  {
    headerText: 'How we help your business?',
    descriptionText: 'Our lightning warning system provides 24/7 monitoring of your location, delivering timely notifications to alert you when dangerous conditions arise, enabling you to take immediate action and protect your business.',
    buttonText: 'Learn More',
    buttonLink: '/industries/mining',
    backgroundImage: 'assets/newlook/images/background/clearcloud.jpg'
  },
  {
    headerText: 'What is AfricaWeather Insure?',
    descriptionText: 'AfricaWeather Insure is an advanced severe weather and hail notification service designed for insurers. It helps mitigate the risk of hail damage and reduces accident claims caused by hazardous weather conditions such as heavy fog, providing timely alerts to protect your policyholders.',
    buttonText: 'Learn More',
    buttonLink: '/industries/insurance',
    backgroundImage: 'assets/newlook/images/background/rain.jpg'
  },
  {
    headerText: 'Introducing our user-centric Weather App',
    descriptionText: "Stay Informed, Anywhere, Anytime. Designed with your needs in mind, our app offers seamless access to accurate weather forecasts and real-time storm tracking, keeping you informed wherever you go.",
    buttonText: 'Learn More',
    buttonLink: '/products/application',
    backgroundImage: 'assets/newlook/images/background/cloudy.jpg'
  }
];
}
