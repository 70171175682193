import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-features',
  template:`
  <section class="difference-section main-container" >
  <dynamic-heading [title]="title" [textColor]="textColor" level="h2"/>
  <div class="feature-container" *ngIf="!isSmallScreen" [ngStyle]="{'grid-template-columns': gridTemplateColumns}" >
    <div *ngFor="let feature of features" class="feature-card">
      <img [src]="feature.imgSrc" width="400" height="200" [alt]="feature.title"  priority/>

      <dynamic-heading [title]="feature.title" [description]="feature.description" [textColor]="textColor" level="h4"/>
    </div>
  </div>
  <div class="feature-container" *ngIf="isSmallScreen" [ngStyle]="{'grid-template-columns': 'repeat(1, 1fr)'}" >
    <div *ngFor="let feature of features" class="feature-card">
      <img [src]="feature.imgSrc" width="400" height="200" [alt]="feature.title"  priority/>
      <dynamic-heading [title]="feature.title" [description]="feature.description" [textColor]="textColor" level="h4"/>
    </div>
  </div>
</section>
  `,
  styles: [`
.difference-section {
  text-align: center;;
  background-color: #0f3a48;
  padding-top: 40px;
  padding-bottom: 40px;
}

.difference-section h2 {
  font-size: 2.5em;
  color: white;
  margin-bottom: 40px;
}

.feature-container {
  display: grid;
  gap: 20px;
}

.feature-card {
  flex: 1;
  text-align: center;
  padding: 40px;
  border-radius: 8px;
  background-color: #0f3a48;
  transition: transform 0.3s ease-in-out;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}


.feature-card:hover {
  transform: scale(1.1);
}

.feature-card img {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.feature-card img:hover {
  fill: #0066cc; /* Example hover color */
}


@media (max-width: 480px) {
  .feature-container {
    flex-direction: column;
    align-items: center;
  }
  .feature-card {
    padding: 10px;
    width: 100%;
  }
  .feature-card img {
    width: 50px;
    height: 50px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 768px) {
  .feature-card{
    padding: 20px;
  }
  .feature-card img {
    width: 75px;
    height: 75px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .feature-card{
    padding: 20px;
  }
}
    `]
})
export class FeaturesComponent {
  @Input() features: { title: string; description: string; imgSrc: string }[] = [];
  @Input() gridTemplateColumns: string =  'repeat(2, 1fr)';
  @Input() textColor: string = 'text-white-color';
  @Input() title: string = 'What Makes Us Different?';
  isSmallScreen: boolean = window.innerWidth <= 768;

  constructor() {
    window.addEventListener('resize', () => {
      this.isSmallScreen = window.innerWidth <= 768;
    });
  }
}
