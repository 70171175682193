import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateImageComponent } from './template-image.component';

@NgModule({
  declarations: [TemplateImageComponent],
  imports: [CommonModule],
  exports: [TemplateImageComponent]
})
export class TemplatesModule { }
