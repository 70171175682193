import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'medium-card-row',
  template: `
   <div class="feature">
            <img [src]="card?.imagePath" [alt]="card?.title+' icons'" class="feature-icon">
            <h4-p-tag [title]="card?.title" [description]="card?.description"></h4-p-tag>

        </div>
  `,
  styles: [`
.feature {

}

.feature-icon {
    width: 100px;
    height: auto;
    margin-bottom: 15px;
}

.feature h4 {
    font-size: 18px;
    font-weight: bold;
    color: #0d1b2a;
    margin-bottom: 10px;
}

.feature p {
    font-size: 14px;
    color: #4a4a4a;
    line-height: 1.5;
}
    `]
})

export class MediumCardRowComponent{
  @Input('card') card: any;
}
