import { Component, Input } from "@angular/core";

@Component({
  selector: 'p-title',
  template: `
      <strong *ngIf="title" class="bold-tag">{{title }}:</strong> {{description }}

  `
})

export class PTitleComponent{
  @Input() title!: string;
  @Input() description!: string;
}
