import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-partner-carousel',
  templateUrl: './partner-carousel.component.html',
  styleUrls: ['./partner-carousel.component.css']
})
export class PartnerCarouselComponent {

  images = [
    {id:"1",src:"assets/images/partners/DTN.png",alt:"DTN",title:"DTN"},
    {id:"2",src:"assets/images/partners/EarthNetworks.png",alt:"EarthNetworks",title:"EarthNetworks"},
    {id:"3",src:"assets/images/partners/ecmwf_logo.jpeg",alt:"ecmwf_logo",title:"ecmwf"},
    {id:"4",src:"assets/images/partners/eumetsat_logo.gif",alt:"eumetsat_logo",title:"eumetsat"},
    {id:"6",src:"assets/images/partners/SAWS.png",alt:"SAWS",title:"SAWS"},
    {id:"7",src:"assets/images/partners/vaisala_logo.jpeg",alt:"vaisala_logo",title:"vaisala"},
    {id:"8",src:"assets/images/partners/WeatherZone.png",alt:"WeatherZone",title:"WeatherZone"},
  ];

  items = ['Item 1', 'Item 2', 'Item 3', 'Item 4'];

  carouselOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['&#8249;', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 3
      },
      1000: {
        items: 4
      }
    }
  };

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 600,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      760: {
        items: 3
      },
      1000: {
        items: 4
      }
    },
    nav: true,
    autoplay: true, // Enable autoplay
    autoplayTimeout: 3000, // Set autoplay interval in milliseconds (e.g., 3 seconds)
    autoplayHoverPause: true
  }

}
