import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { V1Component } from './v1.component';

const routes: Routes = [

  {path:'',component:V1Component ,children:[
    {path:'',loadChildren:()=>import('./pages/pages.module').then(m=>m.PagesModule)},
    // {path:'pages',loadChildren:()=>import('./pages/pages.module').then(m=>m.PagesModule)},
    // {path:'products',loadChildren:()=>import('./pages/products/products.module').then(m=>m.ProductsModule)},
    // {path:'services',loadChildren:()=>import('./pages/services/services.module').then(m=>m.ServicesModule)},
    // {path:'industries',loadChildren:()=>import('./pages/industries/industries.module').then(m=>m.IndustriesModule)}

  ]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class V1RoutingModule { }
