import { Component, OnInit } from '@angular/core';
import { register } from 'swiper/element/bundle';

export interface carouselItemInterface{
  imagepath: string;
  title: string;
}
// Register Swiper custom elements
register();

@Component({
  selector: 'icons-carousel',
  template: `
    <swiper-container
      [slidesPerView]="getSlidesPerView()"
      [spaceBetween]="10"
      [centeredSlides]="true"
      [loop]="true"
      [initialSlide]="carouselItems.length - 1"
      [autoplay]="{
        delay: 3000,
        disableOnInteraction: false
      }"
    >
      <swiper-slide *ngFor="let item of carouselItems">
        <div class="carousel-slide">
          <img [src]="item.imagepath" [alt]="item.title" *ngIf="item.imagepath" style="width: 150px; height: 150px;">
          <p>{{item.title}}</p>
        </div>
      </swiper-slide>
    </swiper-container>
  `,

})
export class CarouselIconsComponent implements OnInit {
  carouselItems:carouselItemInterface[] = [
    // Your carousel items
    {
      imagepath: 'assets/images/icons/our-tech/Accurate Forecasts.png',
      title: 'Accurate Forecasts',
    },
    {
      imagepath: 'assets/images/icons/our-tech/Actionable forecasts.png',
      title: 'Actionable forecasts',
    },
    {
      imagepath: 'assets/images/icons/our-tech/Advanced Predictive.png',
      title: 'Advanced Predictive',
    },
    {
      imagepath: 'assets/images/icons/our-tech/Customised Alerts for.png',
      title: 'Customised Alerts for',
    }
    , {
      imagepath: 'assets/images/icons/our-tech/Dynamic dashboards.png',
      title: 'Dynamic dashboards',
    },
    {
      imagepath: 'assets/images/icons/our-tech/Hyper-local Alerts.png',
      title: 'Hyper-local Alerts',
    }
    , {
      imagepath: 'assets/images/icons/our-tech/Maximise Cost Efficiency.png',
      title: 'Maximise Cost Efficiency',
    }
    , {
      imagepath: 'assets/images/icons/our-tech/Meteorological Intelligence.png',
      title: 'Meteorological Intelligence',
    }
    , {
      imagepath: 'assets/images/icons/our-tech/Robust Platform.png',
      title: 'Robust Platform',
    }
    , {
      imagepath: 'assets/images/icons/our-tech/Smarter Business Data.png',
      title: 'Smarter Business Data',
    }
  ];

  ngOnInit() {
    // Optional initialization
  }

  getSlidesPerView(): number {
    const width = window.innerWidth;
    if (width <= 768) {
      return 2; // Mobile
    } else if (width <= 1024) {
      return 4; // Tablet
    } else {
      return 1.2; // Default for larger screens
    }
  }
}
