import { Component } from '@angular/core';

@Component({
  selector: 'app-schoolsand-universities',
  templateUrl: './schoolsand-universities.component.html',
  styleUrls: ['./schoolsand-universities.component.css']
})
export class SchoolsandUniversitiesComponent {

}
