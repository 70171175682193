// src/app/shared/alert-banner/alert-banner.component.ts
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService, AlertConfig } from '../alert.service';

@Component({
  selector: 'app-alert-banner',
  template:`
  <!-- src/app/shared/alert-banner/alert-banner.component.html -->
<div class="alert-banner" *ngIf="visible" [ngStyle]="{'background-color': getBackgroundColor()}">
  <div class="alert-content">
    <div class="alert-icon" *ngIf="showIcon">{{ getIcon() }}</div>
    <div class="alert-message">{{ message }}</div>
    <div class="alert-timer" *ngIf="timeLeft > 0">({{ timeLeft }}s)</div>
  </div>
  <button *ngIf="dismissible" class="alert-close" (click)="close()">×</button>
</div>
  `,
  styleUrls: ['./alert-banner.component.css']
})
export class AlertBannerComponent implements OnInit, OnDestroy {
  message: string = '';
  type: 'error' | 'warning' | 'info' | 'success' = 'error';
  showIcon: boolean = true;
  dismissible: boolean = true;
  visible: boolean = false;
  timeLeft: number = 0;
  interval: any;

  private subscription: Subscription = new Subscription();

  constructor(private alertService: AlertService) {}

  ngOnInit(): void {
    this.subscription = this.alertService.getAlerts().subscribe(config => {
      if (config) {
        this.message = config.message;
        this.type = config.type;
        this.showIcon = config.showIcon !== undefined ? config.showIcon : true;
        this.dismissible = config.dismissible !== undefined ? config.dismissible : true;
        this.visible = true;

        // Set up timer if duration is provided
        if (config.duration) {
          this.timeLeft = Math.floor(config.duration / 1000);
          this.startCountdown();
        } else {
          this.timeLeft = 0;
          this.stopCountdown();
        }
      } else {
        this.visible = false;
        this.stopCountdown();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.stopCountdown();
  }

  getBackgroundColor(): string {
    const colors = {
      'error': '#f02232',
      'warning': '#ff9800',
      'info': '#2196f3',
      'success': '#4caf50'
    };
    return colors[this.type];
  }

  getIcon(): string {
    const icons = {
      'error': '⚠️',
      'warning': '⚠️',
      'info': 'ℹ️',
      'success': '✅'
    };
    return icons[this.type];
  }

  close(): void {
    this.alertService.dismissAlert();
  }

  startCountdown(): void {
    this.stopCountdown();
    this.interval = setInterval(() => {
      this.timeLeft--;
      if (this.timeLeft <= 0) {
        this.stopCountdown();
      }
    }, 1000);
  }

  stopCountdown(): void {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }
}
