import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'h4-p-tag',
  template: `
     <h4 class="text-primary-color">{{title}}</h4>
      <p>
       {{description}}
      </p>
  `
})

export class H4PComponent{
  @Input() title!: string;
  @Input() description!: string;
}
