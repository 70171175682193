import {Component, Input } from '@angular/core';
import { MainCardData } from 'src/app/shared/interfaces/cards/main-card.interface';

@Component({
  selector: 'home-slider',
  template: `
  <div id="header-carousel" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <ng-container *ngFor="let slide of slides; let i = index">
        <div class="carousel-item" [class.active]="i === 0">
          <main-card [info]="slide "></main-card>
        </div>
      </ng-container>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#header-carousel"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#header-carousel"
        data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
    </button>
  </div>
  `,
  styleUrls: ['./home-slider.component.css'],
})
export class HomeSliderComponent {
  @Input() slides: MainCardData[] = [];
}
