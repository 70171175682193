<header title="About Us"
  description="AfricaWeather: We interpret weather information and transform it to be more understandable to you."
  background_image_path="assets/images/covers/undertherain.png"></header>
<div class="main-container">
  <div class="main-content">
    <dynamic-heading title="AfricaWeather: Transforming Weather Data into Actionable Insights"
      description="AfricaWeather, a DTN-affiliated company, goes beyond weather forecasting – we deliver actionable insights that help businesses manage risk, optimise operations, and make smarter decisions. Leveraging advanced weather intelligence, we aggregate, model, and transform data into insights that empower over 15 industries."
      level="h2" />
    <p>
      As Africa’s leading weather insights provider, trusted by 25 of the top 40 JSE companies, we offer real-time
      alerts
      and predictive analytics through APIs, mobile apps, and web platforms. Powered by our Total Lightning Network and
      partnerships with SAWS and global providers, we provide the data businesses need to protect assets, streamline
      workflows,
      and seize opportunities.
    </p>
    <dynamic-heading title="Empowering Businesses with Weather Intelligence"
      description="We use AI, machine learning, and advanced modelling to deliver four key benefits:" level="h4" />
    <benefit-cards [benefits]="benefits" />
    <p>
      At AfricaWeather, we are not just a weather company; we are an insights company, turning complex data into
      intelligence
      that drives success.
    </p>
    <div class="partners-container">
      <dynamic-heading title="Our Partners"
        description="We collaborate with leading weather providers to deliver the most accurate, reliable, and timely weather insights."
        level="h3" />
      <div class="row">
        <!-- <div class="col-lg-3 col-md-6 mb-4">
          <div class="card">
            <img class="img-fluid" src="assets/images/partners/WeatherZone.png" alt="WeatherZone" class="card-image">
          </div>
        </div> -->
        <div class="col-lg-3 col-md-6 mb-4">
          <div class="card">
            <img class="img-fluid" src="assets/images/partners/DTN.png" alt="DTN" class="card-image">
          </div>
        </div>
        <div class="col-lg-3 col-md-6 mb-4">
          <div class="card">
            <img class="img-fluid" src="assets/images/partners/SAWS.png" alt="SAWS" class="card-image">
          </div>
        </div>
        <div class="col-lg-3 col-md-6 mb-4">
          <div class="card">
            <img class="img-fluid" src="assets/images/partners/EarthNetworks.png" class="card-image"
              alt="EarthNetworks">
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="sihle-container">
  <div class="sihle-text-content">
    <h1>We not just interpreting weather information ,we inspire young talent and educate them about weather.</h1>
    <p>We're not just about interpreting weather—we’re about inspiring and educating the next generation. Through
      mentorship and learning programs, we spark curiosity in young minds, helping them explore the science of weather
      and its impact on our world.</p>
  </div>
  <div class="video-container">
    <div class="fb-video" data-href="https://www.facebook.com/watch/?v=988894466195455" data-width="500"
      data-show-text="false">
      <div class="fb-xfbml-parse-ignore">
        <blockquote cite="https://www.facebook.com/watch/?v=988894466195455"></blockquote>
      </div>
    </div>
  </div>
</div>
