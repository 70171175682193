import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'v1-app',
  template: `
  <router-outlet></router-outlet>
  `
})

export class V1Component implements OnInit {
  constructor() { }

  ngOnInit() { }
}
