<nav class="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
  <a routerLink="/" class="navbar-brand p-0">
    <img [src]="logoSrc" alt="Logo" class="img-fluid logo">
  </a>
  <button class="navbar-toggler" type="button" id="barMenuId" data-bs-toggle="collapse"
    data-bs-target="#navbarCollapse">
    <span class="fa fa-bars"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarCollapse">
    <div class="navbar-nav ms-auto py-0">

      <a routerLink="/" (click)="closeMenu()" class="nav-item nav-link">Home</a>

      <!-- Industries -->
      <nav-dropdown [dropdownMenu]="industriesMenu" (closeBarMenu)="closeMenu()" title="Industries"></nav-dropdown>
      <!-- Products -->
      <nav-dropdown [dropdownMenu]="productsMenu"  (closeBarMenu)="closeMenu()" title="Products"></nav-dropdown>
      <!-- Services -->
      <nav-dropdown [dropdownMenu]="servicesMenu"(closeBarMenu)="closeMenu()"  title="Services"></nav-dropdown>
      <!-- In your template -->
      <!-- <mega-nav-dropdown title="Products & Services" [megaMenu]="megaMenuData" [featuredContent]="featuredContent"
        (closeBarMenu)="closeMenu()">
      </mega-nav-dropdown> -->

      <a routerLink="/about" (click)="closeMenu()" class="nav-item nav-link">About</a>
      <a routerLink="/contact" (click)="closeMenu()" class="nav-item nav-link">Contact</a>
    </div>
    <button type="button" class="login-btn" (click)="onNavigate()">Portal</button>

  </div>
</nav>
