import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextsModule } from './texts/texts.module';
import { TemplatesModule } from './template/template.module';
import { ListsModule } from './lists/lists.module';
import { CardsModule } from './cards/cards.module';
import { ImagesModule } from './images/images..module';
import { SlidersModule } from './sliders/sliders.module';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    TextsModule,
    CardsModule,
    TemplatesModule,
    ListsModule,
    ImagesModule,
    SlidersModule
],
  exports: [
    TextsModule,
    CardsModule,
    TemplatesModule,
    ListsModule,
    ImagesModule,
    SlidersModule

  ]
})
export class UIModule { }
