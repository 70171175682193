import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { CarouselComponent } from './ui-components/carousel/carousel.component';
import { RouterModule } from '@angular/router';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavDropdownComponent } from './ui-components/nav-dropdown/nav-dropdown.component';
import { HeaderNavComponent } from './ui-components/header-nav/header-nav.component';
import { Routes } from './utils/router';
import { CookiesNoticeComponent } from './components/cookies-notice/cookies-notice.component';
import { CookieService } from 'ngx-cookie-service';
import { AboutHomeComponent } from './components/components/about-home.component';
import { IntroHeaderComponent } from './components/components/intro-header.component';
import { SolutionsContainerComponent } from './components/components/solutions-section.component';
import { TechnologySectionComponent } from './components/components/technology-section.component';
import { SliderModule } from './components/sliders/slider.module';
import { IndustrycomponentsModule } from '../pages/industries/components/Industrtcomponent.module';
import { OurTechComponent } from './components/components/new-out-tect.component';
import { CarouselIconsComponent } from './components/components/techIcons.component';
import { NavModule } from './ui-components/navs/nav.module';
import { UIModule } from './ui-components/us-component.module';
@NgModule({
  declarations: [
    NavbarComponent,
    CarouselComponent,
    ScrollToTopComponent,
    NavDropdownComponent,
    HeaderNavComponent,
    CookiesNoticeComponent,
    IntroHeaderComponent,
    AboutHomeComponent,
    TechnologySectionComponent,
    SolutionsContainerComponent,
    OurTechComponent,
    CarouselIconsComponent,

  ],
  imports: [
    // BrowserModule,
    CommonModule,
    RouterModule,
    CarouselModule ,
    BrowserAnimationsModule,
    SliderModule,
    IndustrycomponentsModule,
    NgOptimizedImage,
    NavModule,
    UIModule,
  ],
  exports: [
    NavbarComponent,
    ScrollToTopComponent,
    HeaderNavComponent,
    CarouselComponent,
    CookiesNoticeComponent,
    IntroHeaderComponent,
    AboutHomeComponent,
    TechnologySectionComponent,
    SolutionsContainerComponent,
    OurTechComponent,
    UIModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers:[Routes,CookieService]
})
export class SharedModule { }
