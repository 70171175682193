import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'small-card',
  template: `
    <div class="industry-item">
        <img [src]="card?.imagePath || 'assets/images/default.png'" [alt]="card?.title || 'No title found'"
          class="industry-icon" />
        <div class="industry-text">
          <h6-p-tag [title]="card?.title || 'No title found'" [description]="card?.description || 'No description found'"></h6-p-tag>
        </div>
      </div>
  `,
  styles: [`
.industry-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.industry-icon {
  width: 100px;
  height: 50px;
  margin-right: 15px;
  object-fit: cover;
}
.industry-text h4 {
  margin: 0;
  color: #0c2d57;
}
.industry-text p {
  margin: 5px 0 0;
  font-size: 16px;
  color: #444;
  line-height: 1.5;
}
@media (max-width: 600px) {
  .industry-item {
    flex-direction: column;
    align-items: flex-start;
  }
  .industry-icon {
    margin-bottom: 10px;
  }
}
    `]
})

export class SmallCardComponent implements OnInit {
  @Input('card') card: any;
  constructor() { }

  ngOnInit() { }
}
