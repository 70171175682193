import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'industry-left-card',
  template: `
    <div class="content">
        <div class="content-text">
          <h2>{{title}}</h2>
          <p class="mb-4"></p>
          <ng-content></ng-content>
        </div>
        <img
          [src]="image"
          [alt]="title"
          class="content-image"
        />
      </div>
  `,

  styleUrls:['./industry.css']
})
export class IndustryLeftCardComponent implements OnInit {
  @Input('title') title: string | undefined;
  @Input('description') description:string | undefined;
  @Input('image') image: string | undefined;
  @Input('htmlContent') htmlContent :string | undefined;
  constructor() {}

  ngOnInit() {}
}
