import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { StormwarningsComponent } from './stormwarnings/stormwarnings.component';
import { MobileAlertComponent } from './pages/alerts/mobile-alert/mobile-alert.component';
import { OneHomeComponent } from './pages/pagesV1/home.component';
import { InsuranceComponent } from './pages/pagesV1/products/insurance/insurance.component';

const routes: Routes = [
  // { path: '', redirectTo: '/', pathMatch: 'full' },

{
  path: '',component:OneHomeComponent
},
  { path: 'v1', component: HomeComponent, title: 'AfricaWeather' },
  { path: 'about', component: AboutComponent, title: 'About | AfricaWeather' },
  { path: 'contact', component: ContactComponent, title: 'Contact | AfricaWeather' },
  { path: 'mobile/how-do-delete-user-data', component: MobileAlertComponent, title: 'Delete User Data | AfricaWeather' },
  { path: 'mobileapps/out-stormwarnings.rss', component: StormwarningsComponent },
  // { path: 'pricing-plan', component: PricingComponent, title: 'Price | AfricaWeather' },
  { path: 'legal/privacypolicy', component: PrivacyPolicyComponent, title: 'Privacy Policy | AfricaWeather' },
  { path: 'industries', loadChildren: () => import('./pages/industries/industries.module').then(m => m.IndustriesModule) },
  { path: 'services', loadChildren: () => import('./pages/services/services.module').then(m => m.ServicesModule) },
  { path: 'products', loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule) },
  // {path:'insurance',loadComponent:()=>import('./pages/pagesV1/products/insurance/insurance.component').then(m =>m.InsuranceComponent),title: 'Insurance | AfricaWeather'},
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
