<section class="difference-section">
  <h2 class="text-color">What Makes Us Different?</h2>
  <div class="feature-container">
    <div *ngFor="let feature of features" class="feature-card">
      <img [ngSrc]="feature.imgSrc" width="400" height="200" [alt]="feature.title"  priority/>
      <h3 class="text-color">{{ feature.title }}</h3>
      <p>{{ feature.description }}</p>
    </div>
  </div>
</section>
