import { Component, Input } from "@angular/core";

@Component({
  selector: 'h6-p-tag',
  template: `
     <h6 class="text-primary-color">{{title}}</h6>
      <p>
       {{description}}
      </p>
  `
})

export class H6PComponent{
  @Input() title!: string;
  @Input() description!: string;
}
