import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'large-card',
  template: `
    <div class="section">
      <div *ngIf="!imagePositionRight" class="image-container">
        <ng-container *ngIf="imagePath && imagePath !== null; else defaultImage">
          <img [src]="imagePath" alt="Image" class="illustration"/>
        </ng-container>
        <ng-template #defaultImage>
        <template-image></template-image>
        </ng-template>
      </div>
      <div class="text-content">
        <ng-container *ngIf="bodyComponent; else defaultBody">
        <h3>{{title}}</h3>
          <ng-content></ng-content>
        </ng-container>
        <ng-template #defaultBody>
          <h3-p-tag [title]="title || 'No Title'" [description]="body || 'No body'"></h3-p-tag>
        </ng-template>
      </div>
      <div *ngIf="imagePositionRight" class="image-container">
        <ng-container *ngIf="imagePath && imagePath !== null; else defaultImage">
          <img [src]="imagePath" alt="Image" class="illustration"/>
        </ng-container>
        <ng-template #defaultImage>
          <template-image></template-image>
        </ng-template>
      </div>
    </div>
  `,
  styles: [`
    .section {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      flex-wrap: wrap;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .text-content {
      flex: 1;
    }
    .image-container {
      width:auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
    .illustration {
      width: 100%;
      height: auto;
    }
    @media (min-width: 768px) {
      .section {
        flex-wrap: nowrap;
      }
      .image-container {
        width: auto;
        margin-bottom: 0;
      }
      img.illustration {
        width: 500px;
        height: 300px;
      }
    }
  `]
})

export class LargeCardComponent implements OnInit {
  @Input() title!: string;
  @Input() body!: string;
  @Input() bodyComponent:boolean=false;
  @Input() imagePath!: string;
  @Input() imagePositionRight: boolean = false;

  constructor() { }

  ngOnInit() { }
}
