import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stormwarnings',
  template: '',
  styleUrls: ['./stormwarnings.component.css']
})
export class StormwarningsComponent {
  constructor(private router: Router) {
    // Perform the redirection in the constructor or ngOnInit
    window.location.href = 'https://v1.africaweather.com/mobileapps/out-stormwarnings.rss';
  }
}
