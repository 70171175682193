<header-nav title="How to delete user data" link="/how-do-delete-user-data"></header-nav>

<div class="row">
  <div class="col-md-6">
    <div class="page-content-holder">
      <div id="content" class="plain-content">
        <div class="content-box">
          <h1>How to Delete User Data</h1>
          <p>
            Please contact support at <a href="mailto:itsupport@africaweather.com">itsupport@africaweather.com</a> to request deletion of your account and associated user data.
          </p>

          <h2>Cancel Your Subscription</h2>
          <p>
            If you no longer need your AfricaWeather Ad-Free or Pro subscription, you can cancel your subscription through the iOS/Android subscription flow.
          </p>

          <div class="platform-section">
            <h4><strong>iOS</strong></h4>
            <p>
              For iOS users, please follow Apple's subscription cancellation guide: <a href="https://support.apple.com/en-au/HT202039" target="_blank">https://support.apple.com/en-au/HT202039</a>
            </p>
          </div>

          <div class="platform-section">
            <h4><strong>Android</strong></h4>
            <p>
              For Android users, please follow Google's subscription cancellation guide: <a href="https://support.google.com/googleplay/answer/7018481" target="_blank">https://support.google.com/googleplay/answer/7018481</a>
            </p>
          </div>

          <div class="note">
            <p><strong>NOTE:</strong><br>
            Please keep in mind that both iOS and Android have subscription renewal policies that require cancellation at least 24 hours before the current subscription period ends to avoid auto-renewal.</p>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="col-lg-5" style="min-height: 500px;">
      <div class="position-relative h-100">
          <img class="position-absolute w-100 h-100 rounded" data-wow-delay="0.9s" src="assets/images/app-left-bg-01.jpg" style="object-fit: cover;">
      </div>
  </div>
</div>

