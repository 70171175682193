import { Component, Input, OnInit } from '@angular/core';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-webserviceprice',
  templateUrl: './webserviceprice.component.html',
  styleUrls: ['./webserviceprice.component.css']
})
export class WebservicepriceComponent implements OnInit {
@Input('title') title:string | undefined;

register_url:string = ';'
constructor(private config:AppConfig){}

  ngOnInit(): void {
    this.register_url=this.config.AFRICAWEATHER_REGISTER_URL;
  }

}
