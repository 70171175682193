import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'benefit-cards',
  template: `
    <div class="benefits-section">
    <h2>{{title}}</h2>
    <div class="benefits-grid" *ngFor="let benefit of benefits">
        <div class="benefit-card">
            <h3-p-tag [title]="benefit.title" [description]="benefit?.description"></h3-p-tag>
        </div>
    </div>
</div>
  `,
  styleUrls: ['./benefit-card.component.css'],
})
export class BenefitCardComponent {
  @Input() benefits: any;
  @Input('title') title!: string;
}
