import { Component, Input, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'industry-card',
  template: `
    <div class="africa-container">
      <ng-container *ngIf="image_first; else textFirst">
    <div class="image-content" >
          <img [src]="image" alt="Hurricane view from space">
      </div>
      <div class="text-content">
          <span class="choose-title text-primary-color">{{title}}</span>
          <p>{{description}}</p>
      </div>
      </ng-container>
      <ng-template #textFirst>
      <div class="text-content">
          <span class="choose-title text-primary-color">{{title}}</span>
          <p>{{description}}</p>
      </div>
        <div class="image-content">
          <img [src]="image" [alt]="title">
        </div>
      </ng-template>
  </div>
  `,
  styleUrls:['./industry.css']
})

export class IndustryCardComponent implements OnInit {
  @Input() image_first = false;
  @Input() title?: string;
  @Input() image?: string;
  @Input() description?: string;

  ngOnInit() {

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    // this.checkScreenSize();
    // console.log(`Is the Image first? ${this.image_first}`)
  }

  private checkScreenSize() {
   // console.log(window.innerWidth);
    const mobileScreenWidth = 768;
    this.image_first = (window.innerWidth >= mobileScreenWidth);
  }
}
