import { Component, Input } from '@angular/core';

@Component({
  selector: 'feature-card',
  template: `
<!-- <div class="feature-card">
      <img [src]="feature.icon" width="400" height="200" [alt]="feature.title"  priority/>
      <h3 class="text-color">{{title }}</h3>
      <p>{{ feature.description }}</p>
    </div> -->
        `,
  styles: [

    `
.feature-card {
  text-align: center;
  padding: 20px;
  background-color: #0c2c3c;
  color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; /* Makes all cards stretch */
}
.icon img {
  width: 50px;
  height: 50px;
}

.icon i {
  font-size: 50px;
  color: #00aaff;
}

h3 {
  margin-top: 10px;
  font-size: 1.2em;
}

p {
  font-size: 0.9em;
  color: #ddd;
}

    `,
  ],
})
export class FeatureCardComponent {
  @Input() icon!: string;
  @Input() title!: string;
  @Input() description!: string;
}
