import { environment } from '../environments/environment';

export class AppConfig {
    private _AFRICAWEATHER_URL: string = "https://www.africaweather.com/";
    private _AFRICAWEATHER_PORTAL_WEB!: string;
    private _AFRICAWEATHER_REGISTER_URL!: string;
    private _BUSINESS_TERMS_AND_CONDITIONS_URL!: string;
    private _POPI_CONTRACT_TERMS_URL!: string;



    constructor() {
        this.setValues();
    }

    public get AFRICAWEATHER_URL(): string {
        return this._AFRICAWEATHER_URL;
    }

    public get AFRICAWEATHER_PORTAL_WEB(): string {
        return this._AFRICAWEATHER_PORTAL_WEB;
    }
    public set AFRICAWEATHER_PORTAL_WEB(value: string) {
        this._AFRICAWEATHER_PORTAL_WEB = value;
    }

    public get AFRICAWEATHER_REGISTER_URL(): string {
        return this._AFRICAWEATHER_REGISTER_URL;
    }
    public set AFRICAWEATHER_REGISTER_URL(value: string) {
        this._AFRICAWEATHER_REGISTER_URL = value;
    }

    public get BUSINESS_TERMS_AND_CONDITIONS_URL(): string {
        return this._BUSINESS_TERMS_AND_CONDITIONS_URL;
    }
    public set BUSINESS_TERMS_AND_CONDITIONS_URL(value: string) {
        this._BUSINESS_TERMS_AND_CONDITIONS_URL = value;
    }

    public get POPI_CONTRACT_TERMS_URL(): string {
        return this._POPI_CONTRACT_TERMS_URL;
    }
    public set POPI_CONTRACT_TERMS_URL(value: string) {
        this._POPI_CONTRACT_TERMS_URL = value;
    }


    public setValues(){
        this.AFRICAWEATHER_PORTAL_WEB=environment.portal_url;
        this.AFRICAWEATHER_REGISTER_URL=`${this._AFRICAWEATHER_PORTAL_WEB}register`;
        this.BUSINESS_TERMS_AND_CONDITIONS_URL=`${this.AFRICAWEATHER_PORTAL_WEB}resources/AfricaWeather_Business_Terms_and_Conditions.pdf`;
        this.POPI_CONTRACT_TERMS_URL=`${this.AFRICAWEATHER_PORTAL_WEB}resources/AfricaWeather_Business_Terms_and_Conditions.pdf`;
    }

}