import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Menu } from 'src/app/shared/utils/menu';

interface MegaMenuItem {
  title: string;
  items: Menu[];
}

@Component({
  selector: 'mega-nav-dropdown',
  template: `
    <div class="nav-item mega-dropdown" (mouseenter)="openMenu()" (mouseleave)="closeMenu()">
      <a href="#" class="nav-item nav-link" data-bs-toggle="dropdown" (keydown)="handleKeydown($event)">
        {{ title }}
      </a>
      <div class="mega-menu" [class.show]="isOpen" >
        <div class="mega-menu-content">
          <!-- Menu Columns -->
          <div class="menu-columns">
            <div class="mega-menu-column" *ngFor="let section of megaMenu">
              <h3>{{ section.title }}</h3>
              <ul>
                <li *ngFor="let item of section.items; let i = index">
                  <a [routerLink]="item.link"
                     (click)="closeMenu()"
                     tabindex="0"
                     (keydown)="navigateDropdown($event, i)">
                    {{ item.name }}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <!-- Featured Content -->
          <div class="featured-content" *ngIf="featuredContent">
            <img [src]="featuredContent.image" [alt]="featuredContent.title">
            <h3>{{ featuredContent.title }}</h3>
            <p>{{ featuredContent.description }}</p>
            <a (click)="portalPage()" target="_blank" class="feature-button">
              {{ featuredContent.buttonText }}
            </a>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./mega-dropdown.component.css']
})
export class MegaNavDropdownComponent {

  @Input() title!: string;
  @Input() megaMenu!: MegaMenuItem[];
  @Input() featuredContent?: {
    image: string;
    title: string;
    description: string;
    link: string;
    buttonText: string;
  };
  // backgroundImage= 'assets/images/covers/road-cones.png';

  @Output() closeBarMenu = new EventEmitter<void>();

  isOpen = false;

  openMenu(): void {
    this.isOpen = true;
  }

  closeMenu(): void {
    this.isOpen = false;
    this.closeBarMenu.emit();
  }
  portalPage() {
    window.open(this.featuredContent?.link, "_blank");
    }

  handleKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      this.openMenu();
      event.preventDefault();
    }
  }

  navigateDropdown(event: KeyboardEvent, index: number): void {
    const items = document.querySelectorAll('.mega-menu a');
    if (event.key === 'ArrowDown') {
      const nextIndex = (index + 1) % items.length;
      (items[nextIndex] as HTMLElement).focus();
      event.preventDefault();
    } else if (event.key === 'ArrowUp') {
      const prevIndex = (index - 1 + items.length) % items.length;
      (items[prevIndex] as HTMLElement).focus();
      event.preventDefault();
    } else if (event.key === 'Escape') {
      this.closeMenu();
    }
  }
}
