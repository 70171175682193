import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyBackgroundDirective } from './lazyBackground.directive';

// Import your directives here
// import { YourDirective } from './your-directive.directive';

@NgModule({
  declarations: [
    // Declare your directives here
    LazyBackgroundDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    // Export your directives here
    LazyBackgroundDirective
  ]
})
export class DirectivesModule { }
