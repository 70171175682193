import { Component, Input } from '@angular/core';

@Component({
  selector: 'header-nav',
  template: `
  <div class="container-fluid bg-primary py-5 bg-header">
    <div class="row py-5">
        <div class="col-12 pt-lg-5 mt-lg-5 text-center">
            <h1 class="display-4 text-white animated zoomIn">{{title}}</h1>
            <a routerLink="/" class="h5 text-white">Home</a>
            <i class="far fa-circle text-white px-2"></i>
            <a [routerLink]="link" class="h5 text-white">{{title}}</a>
        </div>
    </div>
</div>
  `,

})
export class HeaderNavComponent {
@Input('title') title:string='';
@Input('link') link:string | undefined;


  @Input('splitImage')
  splitImage: any =null;

image="assets/images/conditions/new/"+this.splitImage;

}
