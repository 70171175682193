<div class="container-fluid bg-primary py-5 bg-header" style="margin-bottom: 90px;">
    <div class="row py-5">
        <div class="col-12 pt-lg-5 mt-lg-5 text-center">
            <h1 class="display-4 text-white animated zoomIn">PRIVACY POLICY STATEMENT</h1>
            <a routerLink="/" class="h5 text-white">Home</a>
            <i class="far fa-circle text-white px-2"></i>
            <a routerLink="/privacy-policy" class="h5 text-white">Privacy policy</a>
        </div>
    </div>
</div>

<div class="privacy-policy">
    <h1>PRIVACY POLICY STATEMENT</h1>
    <p>AfricaWeather is committed to protecting your privacy and developing technology that gives you the most powerful and safe online experience. This Statement of Privacy applies to the Weather Intelligence Systems (Pty) Ltd t/a AfricaWeather website (“Website”) and governs data collection and usage. By using the Website, you consent to the data practices described in this statement.</p>
    <section>
        <h2>1. Protection of Personal Information Act, 2013 (POPI)</h2>
        <p>1.1 You acknowledge that by using the Website and by doing business with AfricaWeather you will be providing
            AfricaWeather with personal data, which may be protected by data protection legislation, including inter
            alia, the Protection of Personal Information Act, 2013 (POPI).</p>

        <p>1.2 You authorize us to process all such personal data and to transmit any such personal data to any
            Affiliate (which Affiliate may also process such personal data) for the purposes of doing business with
            AfricaWeather and in furtherance of AfricaWeather legitimate interests including statistical analysis,
            marketing of our services and credit control.</p>

        <p>1.3 You also authorize us to process and transmit your personal data in the manner contemplated below in this
            Statement of Privacy. For purposes of this Statement of Privacy, "Affiliate" means any member of the
            AfricaWeather group of companies, including without limitation, any subsidiary, sub-subsidiary, holding
            company, fellow subsidiary of any holding company of AfricaWeather.</p>

        <p>1.4 You authorize AfricaWeather to furnish information, which may be protected by data protection
            legislation, including inter alia, POPI, regarding your business relationship with AfricaWeather in good
            faith for any lawful purpose.</p>
    </section>

    <section>
        <h2>2. Collection of your Personal Information</h2>
        <p>2.1 AfricaWeather collects personally identifiable information, such as your e-mail address, name, home or
            work address or telephone number. AfricaWeather also collects anonymous demographic information, which is
            not unique to you, such as your ZIP code, age, gender, preferences, interests, and favorites.</p>

        <p>2.2 There is also information about your computer hardware and software that is automatically collected by
            AfricaWeather. This information can include: your IP address, browser type, domain names, access times, and
            referring Website addresses.</p>

        <p>2.3 Please keep in mind that if you directly disclose personally identifiable information or personally
            sensitive data through AfricaWeather public message boards, this information may
            be collected and used by others.</p>
        <p>Note: AfricaWeather does not read any of your private online communications. AfricaWeather encourages you to
            review the privacy statements of websites you choose to link to from
            AfricaWeather so that you can understand how those websites collect, use and share your information.
            AfricaWeather is not responsible for the privacy statements or other content on
            websites outside of the AfricaWeather Websites (including Websites of our Affiliates).</p>
    </section>

    <section>
        <h2>3. Use of your Personal Information</h2>
        <p>3.1 AfricaWeather collects and uses your personal information to operate the AfricaWeather Website and
            deliver the services you have requested. AfricaWeather also uses your personally
            identifiable information to inform you of other products or services available from AfricaWeather and its
            affiliates.</p>
        <p>3.2 AfricaWeather may also contact you via surveys to conduct research about your opinion of current services
            or of potential new services that may be offered. AfricaWeather does not sell,
            rent or lease its customer lists to third parties. AfricaWeather may, from time to time, contact you on
            behalf of external business partners about a particular offering that may be of interest to you.</p>
        <p>In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is
            not transferred to the third party. In addition, AfricaWeather may share
            data with trusted partners to help us perform statistical analysis, send you email or postal mail, provide
            customer support, or arrange for deliveries. All such third parties are prohibited from
            using your personal information except to provide these services to AfricaWeather, and they are required to
            maintain the confidentiality of your information.</p>
        <p>3.3 AfricaWeather does not use or disclose sensitive personal information, such as race, religion, or
            political affiliations, without your explicit consent. AfricaWeather keeps track of the Websites and pages
            our customers visit within AfricaWeather, in order to determine what
            AfricaWeather services are the most popular.</p>
        <p>This data is used to deliver customised content and advertising within AfricaWeather to customers whose
            behaviour indicates that they are interested in a particular subject area.</p>
        <p>3.4 AfricaWeather Websites will disclose your personal information, without notice, only if required to do so
            by law or in the good faith belief that such action is necessary to: (a) conform
            to the provisions of any law in force from time to time or comply with legal process served on AfricaWeather
            or the Website; (b) protect and defend the rights or property of AfricaWeather;
            and, (c) to protect the personal safety of users of AfricaWeather, or the public.</p>
        <p>3.5 The AfricaWeather app collects location data to update your weather forecasts as you move. Background
            location data is also used to keep widget data up to date even when the app is closed or not in use.
            Location preferences can be changed at any time in your device's settings.</p>

    </section>

    <section>
        <h2>4. Use of Cookies</h2>
        <p>4.1 The AfricaWeather Website use "cookies" to help you personalise your online experience. A cookie is a
            text file that is placed on your hard disk by a Web page server. Cookies cannot be used to run programs or
            deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server
            in the domain that issued the cookie to you. One of the primary purposes of cookies is to provide a
            convenience feature to save you time. The purpose of a cookie is to tell the web server that you have
            returned to a specific page. For example, if you personalise AfricaWeather pages, or register with
            AfricaWeather site or services, a cookie helps AfricaWeather to recall your specific information on
            subsequent visits. This simplifies the process of recording your personal information, such as billing
            addresses, shipping addresses, and so on. When you return to the same AfricaWeather Website, the information
            you previously provided can be retrieved, so you can easily use the AfricaWeather features that you
            customised. You have the ability to accept or decline cookies. Most Web browsers automatically accept
            cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to
            decline cookies, you may not be able to fully experience the interactive features of the AfricaWeather
            services or Websites you visit.</p>
    </section>

    <section>
        <h2>5. Security of your Personal Information</h2>
        <p>AfricaWeather secures your personal information from unauthorised access, use or disclosure. AfricaWeather secures the personally identifiable information you provide on computer servers
			in a controlled, secure environment, protected from unauthorised access, use or disclosure. When personal information (such as a credit card number) is transmitted to other websites, it is
			protected through the use of encryption, such as the Secure Socket Layer (SSL) protocol.</p>
    </section>


    <section>
        <h2>6. Changes to this Statement of Privacy</h2>
        <p>AfricaWeather will occasionally update this Statement of Privacy to reflect company and customer feedback. AfricaWeather encourages you to periodically review this Statement of
			Privacy to be informed of how AfricaWeather is protecting your information.</p>
    </section>

    <section>
        <h2>7. Contact Information</h2>
        <p>AfricaWeather welcomes your comments regarding this Statement of Privacy. If you believe that AfricaWeather
            has not adhered to this Statement, please contact AfricaWeather at <a
                href="mailto:admin@africaweather.com">admin@africaweather.com</a>.</p>
    </section>
</div>