import { Component, OnInit } from '@angular/core';
import { Menu } from '../utils/menu';
import { Routes } from '../utils/router';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  industriesMenu:Menu[]=[];
  businessTermsAndContionsUrl: string | undefined;
  popiUrl: string | undefined;
  webUrl: any;
  constructor(private routes:Routes,private config:AppConfig){}
  ngOnInit(): void {
    this.industriesMenu=this.routes.industriesMenu;
    this.businessTermsAndContionsUrl = this.config.BUSINESS_TERMS_AND_CONDITIONS_URL;
    this.popiUrl = this.config.POPI_CONTRACT_TERMS_URL;
    this.webUrl = this.config.AFRICAWEATHER_URL;
}

}
