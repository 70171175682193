import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Feature } from 'src/app/shared/interfaces/feature/feature.interface';
import { LoadingService } from 'src/app/shared/loader/loading.service';
import { AlertService } from 'src/app/shared/ui-components/banners/alert.service';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {
  contactForm: FormGroup;
  isCompany: boolean = false;
  benefits: Feature[] = [];
  captchaResponse: string | null = null;
  site_key='6LeIYPcqAAAAAI5qlRpEwSB2scU9mmUQs8a-R3Ob';
  contactDetailsHtml: string = `
  <p>
    <strong>Call:</strong> +27 (0)11-300 7480<br>
    <strong>Email:</strong> <br>
    <strong>Visit:</strong> First Floor, Building 15, The Woodlands Office Park, 20 Woodlands Drive, Woodmead, 2080
  </p>
`;

  contactDetails:Feature[] = [
    {
      title: 'Call',
      description: '+27 (0)11-300 7480',
      imagePath: 'assets/images/map/hurricane.png',
      hasComponent: true,
      component: `<div class="text-center"> <p><a href="tel:+27113007480">+27 (0)11-300 7480</a></p></div>`
    },
    {
      title: 'Email',
      description: ' <strong>Call:</strong> +27 (0)11-300 7480<br> <strong>Email:</strong> <br> <strong>Visit:</strong> First Floor, Building 15, The Woodlands Office Park, 20 Woodlands Drive, Woodmead, 2080',
      imagePath: 'assets/images/map/hurricane.png',
      hasComponent: true,
      component: `<div class="text-center"> <p> <strong>Sales</strong><br> <a href="mailto:sales@africaweather.com?subject=Sales Inquiry from Website&">sales@africaweather.com</a> <br> <strong>Technical Support</strong><br><a href="mailto:itsupport@africaweather.com?subject=Technical Support">itsupport@africaweather.com</a><br> <strong>Finance</strong><br><a href="mailto:accounts@africaweather.com?subject=Technical Support">accounts@africaweather.com</a></p></div>`
    },
    {
      title: 'Visit',
      description: "First Floor, Building 15, The Woodlands Office Park, 20 Woodlands Drive, Woodmead, 2080",
      imagePath: 'assets/images/map/hurricane.png'
    }
  ];

  constructor(private fb: FormBuilder,private loadingService: LoadingService,private alertService: AlertService) {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      contact: ['', [Validators.required, Validators.pattern(/^(0\d{9})$/)]],
      email: ['', [Validators.required, Validators.email]],
      contactType: ['', Validators.required],
      companyName: [''],
      companyEmail: ['',[Validators.email]],
      companyContact: ['',[Validators.pattern(/^(0\d{9})$/)]],
      companySize: [''],
      reason: ['', Validators.required],
      message: ['', Validators.required]
    });

    // Listen for changes in contactType field
    this.contactForm.get('contactType')?.valueChanges.subscribe(value => {
      this.isCompany = value === 'company';
      this.toggleCompanyFields();
    });
  }

  toggleCompanyFields(): void {
    if (this.isCompany) {
      this.contactForm.get('companyName')?.setValidators(Validators.required);
      this.contactForm.get('companySize')?.setValidators(Validators.required);
    } else {
      this.contactForm.get('companyName')?.clearValidators();
      this.contactForm.get('companySize')?.clearValidators();
    }
    this.contactForm.get('companyName')?.updateValueAndValidity();
    this.contactForm.get('companySize')?.updateValueAndValidity();
  }

  submitForm(): void {
    if (!this.captchaResponse) {
      // alert('Please verify reCAPTCHA');
      this.alertService.showError('Please verify reCAPTCHA!', 5000);
      return;
    }
    if (this.contactForm.valid) {
      this.loadingService.show();
      // const formData = {
      //   ...this.contactForm.value,
      //   recaptchaToken: this.recaptchaToken,
      // };
      console.log('Form Submitted:', this.contactForm.value);
      this.alertService.showSuccess('Form submitted successfully!', 5000);
      this.contactForm.reset();
      this.loadingService.show();
    }
  }
  handleCaptchaResponse(response: string) {
    this.captchaResponse = response;
    console.log('reCAPTCHA token:', response);
  }

}
