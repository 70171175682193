import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'h2-p-tag',
  template: `
     <h2>{{title}}</h2>
      <p>
       {{description}}
      </p>
  `
})

export class H2PComponent{
  @Input() title!: string;
  @Input() description!: string;
}
