<section class="difference-section">
  <h2 class="text-color">What Makes Us Different?</h2>
  <div class="feature-container">
    <div class="feature-card">
      <img class="responsive-icon" src="assets/newlook/images/icons/cloud-icon.png" alt="Data-Driven Solutions" />

      <h3 class="text-color">Data-Driven Solutions</h3>
      <p>
        As Africa’s leading commercial weather service provider, AfricaWeather offers intuitive,
        data-driven solutions that safeguard both people and assets while directly impacting client profitability.
      </p>
    </div>
    <div class="feature-card">
      <img src="assets/newlook/images/icons/lightning-icon.png" alt="Expert Technology" />

      <h3 class="text-color">Expert Technology</h3>
      <p>
        By expertly managing weather risks, our technology minimises operational disruptions and prioritises safety,
        allowing clients to make strategic, time-sensitive decisions with confidence.
      </p>
    </div>
    <div class="feature-card">

      <img src="assets/newlook/images/icons/mea-icon.png" alt="Industry-Leading Accuracy" />
      <h3 class="text-color">Industry-Leading Accuracy</h3>
      <p>
        Our industry-leading accuracy empowers businesses to stay resilient, proactive, and well-prepared for any
        weather scenario, reinforcing our commitment to delivering value across every sector we serve.
      </p>
    </div>
  </div>
</section>
