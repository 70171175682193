<div *ngIf="!cookiesAccepted && !cookiesRejected" class="alert alert-primary alert-dismissible col-12 footer-cookie-notice" role="alert">
  <div class="row">
    <div class="col-12">
      <h2 class="text-left">Cookie Notice</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <p>
        We use cookies to improve your experience, analyze site traffic, and personalize content.
        By continuing to use this site, you agree to our
        <a routerLink="/legal/privacypolicy" rel="noreferrer">Privacy Policy</a>.
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <button type="button" class="btn btn-primary" (click)="acceptCookies()" aria-label="Accept">
        Accept
      </button>
      <button type="button" class="btn btn-danger" (click)="rejectCookies()" aria-label="Reject">
        Reject
      </button>
    </div>
  </div>
</div>
