import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecaptchaModule } from 'ng-recaptcha';
import { ReCaptchaComponent } from './reCapture/re-captcha.component';

@NgModule({
  declarations: [
    // Add your form components here
    ReCaptchaComponent
  ],
  imports: [
    CommonModule,
    RecaptchaModule
  ],
  exports: [
    ReCaptchaComponent
  ]
})
export class AWFormsModule { }
