import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'card-footer',
  template: `
    <div class="solutions-section">
    <h2 class="text-primary-color">SOLUTIONS</h2>
    <div class="solutions-grid">
        <div class="solution-card">
        <div class="card">
                <a routerLink="/industries/mining">
                    <img src="assets/newlook/images/AfricaWeather - Mining.jpg" alt="Mining Industry" class="card-img">
                    <p>MINING</p>
                </a>
            </div>
        </div>

        <div class="solution-card">
        <div class="card">
                <a routerLink="/industries/insurance">
                    <img src="assets/newlook/images/screen_2x (4).jpg" alt="Insurance Industry" class="card-img">
                    <p>INSURANCE</p>
                </a>
            </div>
        </div>

        <div class="solution-card">
        <div class="card">
                <a routerLink="/industries/schools-and-universities">
                    <img src="assets/newlook/images/s2 (6).jpg" alt="Schools &amp; Universities" class="card-img">
                    <p>SCHOOLS & UNIVERSITIES</p>
                </a>
            </div>
        </div>

        <div class="solution-card">
        <div class="card">
                <a routerLink="/industries/sporting">
                    <img src="assets/newlook/images/golfimg.jpg" alt="Sporting Facilities" class="card-img">
                    <p>SPORTING FACILITIES</p>
                </a>
            </div>
        </div>
    </div>
</div>
  `,
  styleUrls:['./card-footer.component.css']
})

export class CardFooterComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
