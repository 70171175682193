import { Component } from '@angular/core';

@Component({
  selector: 'app-precision-farming',
  templateUrl: './precision-farming.component.html',
  styleUrls: ['./precision-farming.component.css']
})
export class PrecisionFarmingComponent {

}
