import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'about-home',
  styleUrls: ['./about-home.component.css'],
  template: `
<div class="aboutTopic">
  <div class="left-column">
    <p class="highlighted-text">
      At AfricaWeather, we are not just a weather company; we’re an insights company, turning complex data into intelligence that drives success.
    </p>
  </div>
  <div class="right-column">
    <p>
      AfricaWeather, a DTN-affiliated company, goes beyond weather forecasting—we deliver actionable insights that help businesses manage risk, optimise operations, and make smarter decisions. Leveraging advanced weather intelligence, we aggregate, model, and transform data into insights that empower over 15 industries.
    </p>
    <p>
      As Africa’s leading weather insights provider, trusted by 25 of the top 40 JSE companies, we offer real-time alerts and predictive analytics through APIs, mobile apps, and web platforms. Powered by our Total Lightning Network and partnerships with SAWS and global providers, we provide the data businesses need to protect assets, streamline workflows, and seize opportunities.
    </p>
  </div>
</div>

  `
})

export class AboutHomeComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
