import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AboutComponent } from './pages/about/about.component';
import { HomeComponent } from './pages/home/home.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { AppConfig } from './app.config';
import { environment } from 'src/environments/environment';
import { StormwarningsComponent } from './stormwarnings/stormwarnings.component';
import { MobileAlertComponent } from './pages/alerts/mobile-alert/mobile-alert.component';
import { OneHomeComponent } from './pages/pagesV1/home.component';
import { VOnepagesModule } from './pages/pagesV1/v-onepages.module';
import { SchoolsandUniversitiesComponent } from './pages/v1/pages/schoolsand-universities/schoolsand-universities.component';
import { SportingComponent } from './pages/v1/pages/sporting/sporting.component';


@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    HomeComponent,
    OneHomeComponent,
    ContactComponent,
    PricingComponent,
    PrivacyPolicyComponent,
    StormwarningsComponent,
    MobileAlertComponent,
    SchoolsandUniversitiesComponent,
    SportingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    VOnepagesModule
  ],
  providers: [AppConfig,{ provide: 'PORTAL_URL', useValue: environment.portal_url },],
  bootstrap: [AppComponent]
})
export class AppModule { }
