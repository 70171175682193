import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sliderfour',
  template: `
  <section class="hero-section-four">
        <div class="hero-content">
            <h1 class="header">Introducing Our User-Centric Weather App: Stay Informed, Anywhere, Anytime</h1>
            <p class="content-slider-four">Our user-centric weather app is designed with you in mind, providing easy access to weather forecasts and real-time storm tracking while you're on the move.</p>
            <button class="btn" routerLink="/products/application">Learn more</button>
        </div>
    </section>
  `,
  styleUrls: ['./slider.component.css']
})

export class SliderFourComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
