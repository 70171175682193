import { Component } from '@angular/core';
import { LoadingService } from './loading.service';

@Component({
  selector: 'app-loading',
  template: `
<div class="overlay" *ngIf="loadingService.isLoading$ | async">
  <div class="snake-loader"></div>
</div>

  `,
  styles: [
    `
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Transparent black */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensures it covers everything */
  pointer-events: all; /* Blocks clicks */
}

.snake-loader {
  width: 80px;
  height: 80px;
  position: relative;
}

.snake-loader::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 80%;
  border: 6px solid transparent;
  border-top-color:rgb(5, 25, 43);
  border-right-color:rgb(4, 187, 243);
  animation: snakeSpin 1s linear infinite;
}

@keyframes snakeSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

    `,
  ],
})
export class LoadingComponent {
  constructor(public loadingService: LoadingService) {}
}
