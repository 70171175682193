import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'h2-p-tag',
  template: `

      <span class="text-primary-color">{{title}}</span>

      <p>
       {{description}}
      </p>
  `,
  styles:[
    `
    span {
    font-size: 48px;
    line-height: 1.2;
}`
  ]
})

export class H2PComponent{
  @Input() title!: string;
  @Input() description!: string;
}
