<app-carousel></app-carousel>

<div class="container-fluid facts pt-lg-0">
    <div class="container py-5 pt-lg-0">
        <div class="row gx-0">
            <div class="col-lg-12 wow zoomIn" data-wow-delay="0.3s">
                <div class="bg-light shadow d-flex align-items-center justify-content-center p-4" style="height: 100px">
                    <div class="ps-4">
                        <h5 class="text-primary mb-0">Business Portal Application</h5>
                        <p class="mb-0" data-toggle="counter-up">
                            To visit our Legacy Site, please click this
                            <a [href]="portal_url" target="_blank">link</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- About Start -->
<div class="container-fluid py-5 wow fadeInUp d-none d-sm-block" data-wow-delay="0.1s">
    <div class="container py-5">
        <div class="row g-5">
            <div class="col-lg-7">
                <div class="section-title position-relative pb-3 mb-5">
                    <h1 class="mb-0">Advanced Solutions</h1>
                </div>
                <p class="mb-4">
                    Start your day off (be it sunshine or gloom), by receiving weather
                    notifications for your location. Track the storm and lightning
                    nationally and provincially, know what activities you can participate
                    in for the day or purchase a lightning or climate report for your
                    records. Be prepared for every season. Be at the forefront of the
                    weather.
                </p>

                <a class="text-left" [href]="register_url" target="_blank"><img src="assets/images/btn-subscribe.png"
                        style="float: right; margin-bottom: 10px" /></a>
            </div>
            <div class="col-lg-5">
                <div class="position-relative h-100">
                    <img class="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s"
                        src="assets/images/home-solutions-1.png" />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card py-3 d-block d-sm-none" style="border-radius: 10px; background-color: #f2f2f2">
    <div class="container-fluid industry">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6 order-1 order-lg-0 industry-content">
                    <div>
                        <h1>Advanced Solutions</h1>
                        <p class="mb-4">
                            Start your day off (be it sunshine or gloom), by receiving weather
                            notifications for your location. Track the storm and lightning
                            nationally and provincially, know what activities you can
                            participate in for the day or purchase a lightning or climate
                            report for your records.Be prepared for every season. Be at the forefront of the weather.
                        </p>

                        <a class="text-left" [href]="register_url" target="_blank"><img
                                src="assets/images/btn-subscribe.png" style="float: right; margin-bottom: 10px" /></a>
                    </div>
                    <div></div>
                </div>

                <div class="col-12 col-lg-5 offset-0 offset-lg-1 order-0 order-lg-1 pb-3 industry-image">
                    <div>
                        <img src="assets/images/home-solutions-1.png" class="img-fluid" alt="content-image" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About End -->

<!-- Features Start -->
<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container py-5">
        <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="">
            <h5 class="fw-bold text-primary text-uppercase">Why Choose Us</h5>
            <p class="mb-0">
                AfricaWeather is the leading commercial weather services provider across
                the continent. We assist our clients in managing weather risks
                efficiently through solutions that are easy to implement and have a
                direct impact on the profitability for our clients.
            </p>
        </div>
        <div class="row g-5">
            <!-- Left Column -->
            <div class="col-lg-6">
                <div class="row g-5">
                    <div class="col-12 wow zoomIn" data-wow-delay="0.2s">
                        <h4 class="text-primary">Alerts</h4>
                        <p class="mb-0">
                            Alerts include Rain, Wind, Fog, Discomfort, Temperature, and
                            Barometric pressure notices.
                        </p>
                    </div>
                    <div class="col-12 wow zoomIn" data-wow-delay="0.6s">
                        <h4 class="text-primary">Storms</h4>
                        <p class="mb-0">
                            Weather patterns, improve forecasting, and warn the public about
                            significant weather conditions.
                        </p>
                    </div>
                    <div class="col-12 wow zoomIn" data-wow-delay="0.4s">
                        <h4 class="text-primary">Maps</h4>
                        <p class="mb-0">
                            Our maps give you a detailed look at the weather, showing
                            everything from rain and humidity to wind speed, cloud cover,
                            lightning, and storms.
                        </p>
                    </div>
                </div>
            </div>

            <!-- Right Column -->
            <div class="col-lg-6 wow zoomIn" data-wow-delay="0.9s" style="min-height: 350px">
                <!-- Image Section -->
                <div class="position-relative h-100">
                    <img class="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.1s"
                        src="assets/images/map/map_lightning.gif" style="max-width: 100%; height: auto" />
                </div>
            </div>
            <div class="col-12 wow zoomIn" data-wow-delay="0.8s">
                <div class="d-flex align-items-center">
                    <h4 class="mb-0 text-primary">Graphs</h4>
                </div>
                <p class="mb-0">
                    Track conditions are influenced by ambient conditions and the
                    environmental impact of weather can lead to unsafe field conditions
                    for rail operators.
                </p>
            </div>
        </div>
    </div>
</div>
<!-- Features Start -->

<!-- Service Start -->
<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container py-5">
        <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px">
            <h5 class="fw-bold text-primary text-uppercase">Our Services</h5>
            <!-- <h1 class="mb-0">Custom IT Solutions for Your Successful Business</h1> -->
        </div>
        <div class="row g-5">
            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                <div
                    class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <div class="service-icon">
                        <div class="icon-sm">
                            <img src="assets/images/logos/industries_Logos/golf _and _school.png"
                                alt="golf _and _school" />
                        </div>
                    </div>
                    <h4 class="mb-3">Golf Courses and Schools</h4>
                    <p class="m-0">
                        We are uniquely positioned to help you manage exposure to lightning
                        at your grounds. Our solution has been adopted extensively across
                        South Africa’s schools and golf courses.
                    </p>
                    <a class="btn btn-lg btn-primary rounded" routerLink="/industries/schools-and-universities">
                        <i class="bi bi-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                <div
                    class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <div class="service-icon">
                        <div class="icon-sm">
                            <img src="assets/images/logos/industries_Logos/logistics-shipping-worldwide.png"
                                alt="logistics-shipping-worldwide" />
                        </div>
                    </div>
                    <h4 class="mb-3">Logistics</h4>
                    <p class="m-0">
                        Track conditions are influenced by ambient conditions and the
                        environmental impact of weather can lead to unsafe field conditions
                        for rail operators.
                    </p>
                    <a class="btn btn-lg btn-primary rounded" routerLink="/industries/logistics">
                        <i class="bi bi-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                <div
                    class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <div class="service-icon">
                        <div class="icon-sm">
                            <img src="assets/images/logos/industries_Logos/data_mining.png" alt="data_mining" />
                        </div>
                    </div>
                    <h4 class="mb-3">Mining</h4>
                    <p class="m-0">
                        It is used extensively by prominent mining houses, which include BHP
                        Billiton, Anglo Gold, Anglo Coal,Exxaro, Aquarius Platinum,
                        Goldfields, ARM and Xstrata.
                    </p>
                    <a class="btn btn-lg btn-primary rounded" routerLink="/industries/mining">
                        <i class="bi bi-arrow-right"></i>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                <div
                    class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <div class="service-icon">
                        <div class="icon-sm">
                            <img src="assets/images/logos/industries_Logos/care-for-security.png"
                                alt="Insurance icon" />
                        </div>
                    </div>
                    <h4 class="mb-3">Insurance</h4>
                    <p class="m-0">
                        AfricaWeather Insure is a severe weather and hail notification
                        service that insurers can utilize to reduce the risk of hail damage
                        and accident claims due to heavy fog amongst their policyholders.
                    </p>
                    <a class="btn btn-lg btn-primary rounded" routerLink="/industries/insurance">
                        <i class="bi bi-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                <div
                    class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <div class="service-icon">
                        <div class="icon-sm">
                            <img src="assets/images/logos/industries_Logos/mining_truck_industry.png"
                                alt="Contruction icon" />
                        </div>
                    </div>
                    <h4 class="mb-3">Construction</h4>
                    <p class="m-0">
                        Whether it’s the scheduling of your crew, managing your project
                        effectively, monitoring safety or implementing cost controls,
                        inclement weather events can reduce your profits.
                    </p>
                    <a class="btn btn-lg btn-primary rounded" routerLink="/industries/construction">
                        <i class="bi bi-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                <div
                    class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <h3 class="mb-3">Media</h3>
                    <p class="mb-3">
                        AfricaWeather offers unique turnkey weather solutions across various
                        industries. We currently provide weather feeds for many top media
                        publishers and websites for various towns in South Africa, Botswana,
                        Zambia, Namibia, Zimbabwe and Mozambique.
                    </p>
                    <a class="btn btn-lg btn-primary rounded" routerLink="/industries/media">
                        <i class="bi bi-arrow-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Service End -->

<!-- Vendor Start -->
<app-partner-carousel></app-partner-carousel>
<!-- Vendor End -->
