import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.css']
})
export class HomeSliderComponent implements OnInit {

  ngOnInit(): void {
    const carousel = document.getElementById('header-carousel');
    if (carousel) {
      carousel.addEventListener('slide.bs.carousel', () => {
        this.performCloudTransaction();
      });
    }
  }

  performCloudTransaction(): void {
    // Your cloud transaction logic here
    console.log('Cloud transaction performed');
  }
}
