import { Component } from '@angular/core';

@Component({
  selector: 'app-sporting',
  templateUrl: './sporting.component.html',
  styleUrls: ['./sporting.component.css']
})
export class SportingComponent {

}
