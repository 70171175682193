import { Component, Input } from '@angular/core';

@Component({
  selector: 'feature-list',
  template: `
    <ul>
      <li *ngFor="let feature of features" style="margin-bottom: 10px;">
        <p-title [title]="feature?.title" [description]="feature?.description"></p-title>

      </li>
    </ul>
  `,
  styles: [`
  li{
  margin-bottom: 10px;
  },
  .bold-tag {
        font-weight: bold;
        color: black;
      }
    `]
})
export class FeatureListComponent {
  @Input() features: any[] = [];
}
