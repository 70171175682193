import { Menu } from "./menu";

export class Routes {
    industriesMenu: Menu[] = [
        { name: 'Mining', link: '/industries/mining' },
        { name: 'Insurance', link: '/industries/insurance' },
        { name: 'Schools and Universities', link: '/industries/schools-and-universities' },
        {name: 'Sporting Facilities',link: "/industries/sporting"
        },
      ];

      productsMenu: Menu[] = [
        { name: 'Mobile Application', link: '/products/application' },
        { name: 'Weather Station', link: '/products/station' },
        { name: 'API Solution', link: '/products/api-solution' },
        { name: 'Web-Based Portal', link: '/products/web-based-portal' },
        // { name: 'How our system works', link: '/products/how-our-system' },
        { name: 'WeatherGuard', link: '/products/weatherguard' },

      ];

      servicesMenu: Menu[] = [
        { name: 'Additional Services', link: '/services/additional-services' },
        { name: 'Implementation and Support', link: '/services/implementation-and-support' },
        { name: 'Hardware Options', link: '/services/hardware-options' },
        // { name: 'Warnings', link: '/warnings' },
        // { name: 'Storms', link: '/storms' },
        // { name: 'Maps', link: '/maps' },
        // { name: 'Graphs', link: '/graphs' },
      ]
}
