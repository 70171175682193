import { Component, Input } from '@angular/core';
export interface slideInterfaces {
  image:string;
  type:string;
  title:string;
  description:string;
  url:string;
}
@Component({
  selector: 'ui-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent {
  @Input()
  slide!: slideInterfaces;

}
