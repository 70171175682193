import { Component, Input, ViewChild } from '@angular/core';
import { MainCardData } from 'src/app/shared/interfaces/cards/main-card.interface';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'home-slider',
  template: `
    <div class="slider-container position-relative">
      <owl-carousel-o [options]="customOptions" (initialized)="onInitialized($event)" #owlCarousel>
        <ng-container *ngFor="let slide of slides">
          <ng-template carouselSlide>
            <main-card [info]="slide"></main-card>
          </ng-template>
        </ng-container>
      </owl-carousel-o>

      <!-- Custom navigation buttons -->
      <div class="slider-nav-buttons d-none d-md-block">
        <button class="slider-nav-button slider-nav-prev" (click)="prevSlide()">
          <i class="fa fa-chevron-left"></i>
        </button>
        <button class="slider-nav-button slider-nav-next" (click)="nextSlide()">
          <i class="fa fa-chevron-right"></i>
        </button>
      </div>

        <!-- Mobile navigation (bottom placement) -->
        <div class="slider-nav-mobile d-md-none">
        <button class="slider-nav-mobile-btn" (click)="prevSlide()">
          <i class="fa fa-chevron-left"></i>
        </button>
        <button class="slider-nav-mobile-btn" (click)="nextSlide()">
          <i class="fa fa-chevron-right"></i>
        </button>
      </div>
    </div>
  `,
  styles: [`
    .slider-container {
      width: 100%;
      position: relative;
    }

    .slider-nav-buttons {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }

    .slider-nav-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 50px;
      height: 50px;
      background: rgba(255, 255, 255, 0);
      border: none;
      border-radius: 50%;
      color: white;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      pointer-events: auto;
      z-index: 10;
      transition: all 0.3s ease;
    }

    .slider-nav-button:hover {
      background: rgba(0, 0, 0, 0.8);
    }

    .slider-nav-prev {
      left: 20px;
    }

    .slider-nav-next {
      right: 20px;
    }
  `],
})
export class HomeSliderComponent {
  @Input() slides: MainCardData[] = [];
  @ViewChild('owlCarousel', { static: false }) owlCarousel!: CarouselComponent;

  // private owlCarousel: any;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 1000, // Duration of animation
  //   animateOut: 'rotateOut', // Optional fade-out effect
  // animateIn: 'rotateIn', // Optional fade-in effect
    responsive: {
      0: {
        items: 1
      }
    },
    nav: false, // Hide default navigation
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: false
  };

  // Get reference to the carousel
  onInitialized(event: any) {
    this.owlCarousel = event.component;
  }

  nextSlide() {
    if (this.owlCarousel) {
      this.owlCarousel.next();
    }
  }

  prevSlide() {
    if (this.owlCarousel) {
      this.owlCarousel.prev();
    }
  }

}
