import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesRoutingModule } from './pages-routing.module';
import { HomeComponent } from './home/home.component';
import { OurTechComponent } from './our-tech/our-tech.component';
import { AgricultureComponent } from './agriculture/agriculture.component';
import { PrecisionFarmingComponent } from './precision-farming/precision-farming.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    HomeComponent,
    OurTechComponent,
    AgricultureComponent,
    PrecisionFarmingComponent
  ],
  imports: [
    PagesRoutingModule,
    SharedModule,
  ]
})
export class PagesModule { }
