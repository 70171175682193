import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'header',
  template: `
 <div
  class="container-fluid bg-primary py-5 bg-header-golf main-container header-container"
  appLazyBackground="{{ background_image_path }}">
    <div class="row py-5">
        <div class="col-12 pt-lg-5 mt-lg-5">
            <span class="h5 text-white animated slideInLeft">{{title}}</span>
            <p class="h5 text-white animated slideInLeft">{{description}}</p>
        </div>
    </div>
</div>

  `,
  styleUrls:['./header.component.css']
})

export class HeaderComponent {
  @Input('title') title:string='';
  @Input('description') description ='';
  @Input('background_image_path') background_image_path='';

}
