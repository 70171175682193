import { Component } from '@angular/core';

@Component({
  selector: 'template-image',
  template: `
    <div class="image-container">
      <svg class="illustration" viewBox="0 0 500 300" xmlns="http://www.w3.org/2000/svg">
        <!-- Sky -->
        <rect width="500" height="300" fill="#b3e5fc"/>
        <!-- Cloud -->
        <circle cx="180" cy="90" r="30" fill="white"/>
        <circle cx="220" cy="90" r="40" fill="white"/>
        <circle cx="260" cy="90" r="30" fill="white"/>
        <!-- Hills -->
        <path d="M0 200 Q150 120, 300 200 T500 200 V300 H0 Z" fill="#7fc96a"/>
        <path d="M0 200 Q100 160, 200 200 T500 200 V300 H0 Z" fill="#5aaf48"/>
      </svg>
    </div>
  `,
  styles: [`
    .image-container {
      width:auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .illustration {
      width: 100%;
      height: auto;
    }
  `]
})
export class TemplateImageComponent {}
