import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'button-scroll-to-top',
  template: `
    <button *ngIf="isVisible" (click)="scrollToTop()" class="scroll-to-top">
      &#8679; <!-- Up arrow icon -->
    </button>
  `,
  styles: [`
    .scroll-to-top {
      position: fixed;
      bottom: 20px;
      right: 20px;
      background-color: #06A3DA;
      color: white;
      border: none;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      font-size: 24px;
      cursor: pointer;
      box-shadow: 0 2px 5px rgba(35, 12, 248, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .scroll-to-top:hover {
      border: 2px solid #0f3a48;
      color: #0f3a48;
      box-shadow: 0 2px 5px rgba(14, 12, 35, 0.3);
}
  `]
})
export class ScrollToTopComponent {
  isVisible = false;

  @HostListener('window:scroll')
  onWindowScroll() {
    this.isVisible = window.pageYOffset > 300;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
