
<div id="header-carousel" class="carousel slide carousel-fade" data-bs-ride="carousel">
    <div class="carousel-inner">
        <div class="carousel-item active">
          <ui-carousel [slide]="slides[0]"></ui-carousel>
        </div>
        <div class="carousel-item">
         <ui-carousel [slide]="slides[1]"></ui-carousel>
        </div>
        <div class="carousel-item">
          <ui-carousel [slide]="slides[2]"></ui-carousel>
        </div>
        
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#header-carousel"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#header-carousel"
        data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
    </button>
</div>