import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'v1-home',
  template: `
  <app-home-slider></app-home-slider>
  <!-- <into-header></into-header> -->
<about-home></about-home>
<technology-section/>
<app-why-us/>
<solutions-section/>


  `
})

export class OneHomeComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
