import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MegaNavDropdownComponent } from './mega-dropdown/mega-dropdown.component';

@NgModule({
  declarations: [MegaNavDropdownComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [MegaNavDropdownComponent]
})
export class NavModule { }
