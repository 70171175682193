import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {
  contactForm: FormGroup;
  reasonOptions: string[] = [
    "Order & application queries",
    "Report an issue",
    "Prepaid Bundle Enquiry",
    "Complaints",
    "Follow up on faults & issues",
    "Do I have Openserve Fibre in my area?",
    "Accounts / Billing",
    "Other"
  ];

  constructor(private fb: FormBuilder) {
    this.contactForm = this.fb.group({
      reason: ['', Validators.required],
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.contactForm.valid) {
      console.log("Form Submitted", this.contactForm.value);
      alert("Your message has been sent!");
      this.contactForm.reset();
    }
  }
}
