import {Component, Input } from '@angular/core';

@Component({
  selector: 'app-img',
  template: `
        <img
          [src]="path"
          [alt]="title"
          class="content-image"
        />
  `,
  styleUrls: ['./img.component.css'],
})
export class ImgComponent {
  @Input('title') title: string | undefined;
  @Input('path') path: string | undefined;
  constructor() {}
}
