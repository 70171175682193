import { Component, Input, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Menu } from '../../utils/menu';

@Component({
  selector: 'nav-dropdown',
  template: `
    <div class="nav-item dropdown"
         (mouseenter)="openMenu()"
         (mouseleave)="closeMenu()"
         [attr.aria-expanded]="isOpen">
      <a href="#"
         class="nav-link dropdown-toggle"
         (click)="toggleMenu($event)"
         (keydown)="handleKeydown($event)"
         role="button"
         [attr.aria-haspopup]="true"
         [attr.aria-expanded]="isOpen">
        {{ title }}
      </a>
      <div class="dropdown-menu m-0"
           [class.show]="isOpen"
           [ngClass]="{'rounded-corners': isOpen}"
           role="menu">
        <ng-container *ngFor="let menu of dropdownMenu; let i = index">
          <!-- Regular menu item without children -->
          <a *ngIf="!menu.children?.length"
             (click)="onItemSelect(menu)"
             [routerLink]="menu?.link"
             class="dropdown-item"
             tabindex="0"
             role="menuitem"
             [class.active]="isActiveRoute(menu.link)"
             (keydown)="navigateDropdown($event, i)">
            {{ menu?.name }}
          </a>

          <!-- Submenu with children -->
          <div *ngIf="menu.children?.length"
               class="dropdown-submenu"
               (mouseenter)="openSubmenu(menu)"
               (mouseleave)="closeSubmenu(menu)">
            <a class="dropdown-item submenu-toggle"
               tabindex="0"
               role="menuitem"
               [class.active]="isActiveRoute(menu.link)"
               (keydown)="navigateDropdown($event, i)">
              {{ menu?.name }}
              <span class="submenu-icon">▶</span>
            </a>
            <div class="submenu" [class.show]="menu.isOpen">
              <a *ngFor="let submenu of menu.children; let j = index"
                 (click)="onItemSelect(submenu)"
                 [routerLink]="submenu?.link"
                 class="dropdown-item"
                 tabindex="0"
                 role="menuitem"
                 [class.active]="isActiveRoute(submenu.link)"
                 (keydown)="navigateSubmenu($event, i, j)">
                {{ submenu?.name }}
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  `,
  styles: [`
    .rounded-corners {
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .dropdown-icon, .submenu-icon {
      font-size: 10px;
      margin-left: 5px;
      transition: transform 0.2s ease;
    }

    .dropdown-item {
      padding: 8px 16px;
      transition: background-color 0.2s ease, color 0.2s ease;
      position: relative;
    }

    .dropdown-item:hover,
    .dropdown-item:focus {
      background-color: #009ad4;
      color: white;
      outline: none;
    }

    .dropdown-item.active {
      background-color: #0082b3;
      color: white;
    }
    .dropdown-menu.show {
      animation: fadeIn 0.2s ease;
    }

    /* Submenu styling */
    .dropdown-submenu {
      position: relative;
    }

    .submenu-toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .submenu {
      position: absolute;
      left: 100%;
      top: 0;
      display: none;
      min-width: 200px;
      background-color: white;
      border-radius: 0 10px 10px 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      z-index: 1001;
    }

    .submenu.show {
      display: block;
      animation: fadeIn 0.2s ease;
    }

    @keyframes fadeIn {
      from { opacity: 0; transform: translateY(-10px); }
      to { opacity: 1; transform: translateY(0); }
    }
  `]
})
export class NavDropdownComponent {
  @Input() dropdownMenu!: Menu[];
  @Input('title') title!: string;
  @Input() activeClass: string = 'active';

  @Output() closeBarMenu = new EventEmitter<void>();
  @Output() itemSelected = new EventEmitter<Menu>();

  isOpen = false;

  constructor(private elementRef: ElementRef, private router: Router) {}

  ngOnInit() {
    // Initialize isOpen property for all menu items with children
    if (this.dropdownMenu) {
      this.dropdownMenu.forEach(menu => {
        if (menu.children) {
          menu.isOpen = false;
        }
      });
    }
  }

  openMenu(): void {
    this.isOpen = true;
  }

  closeMenu(): void {
    this.isOpen = false;
    // Close all submenus when main menu closes
    if (this.dropdownMenu) {
      this.dropdownMenu.forEach(menu => {
        if (menu.children) {
          menu.isOpen = false;
        }
      });
    }
    this.closeBarMenu.emit();
  }

  toggleMenu(event: Event): void {
    event.preventDefault();
    this.isOpen = !this.isOpen;

    // If opening the menu, focus on first item
    if (this.isOpen) {
      setTimeout(() => {
        const items = this.elementRef.nativeElement.querySelectorAll('.dropdown-menu > .dropdown-item, .dropdown-menu .submenu-toggle');
        if (items.length > 0) {
          items[0].focus();
        }
      }, 100);
    }
  }

  openSubmenu(menu: Menu): void {
    menu.isOpen = true;
  }

  closeSubmenu(menu: Menu): void {
    menu.isOpen = false;
  }

  handleKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      this.toggleMenu(event);
      event.preventDefault();
    } else if (event.key === 'ArrowDown' && !this.isOpen) {
      this.openMenu();
      setTimeout(() => {
        const items = this.elementRef.nativeElement.querySelectorAll('.dropdown-menu > .dropdown-item, .dropdown-menu .submenu-toggle');
        if (items.length > 0) {
          items[0].focus();
        }
      }, 100);
      event.preventDefault();
    }
  }

  navigateDropdown(event: KeyboardEvent, index: number): void {
    const items = this.elementRef.nativeElement.querySelectorAll('.dropdown-menu > .dropdown-item, .dropdown-menu .submenu-toggle');
    const menu = this.dropdownMenu[index];

    switch (event.key) {
      case 'ArrowDown':
        const nextIndex = (index + 1) % items.length;
        items[nextIndex].focus();
        event.preventDefault();
        break;

      case 'ArrowUp':
        const prevIndex = (index - 1 + items.length) % items.length;
        items[prevIndex].focus();
        event.preventDefault();
        break;

      case 'ArrowRight':
        if (menu.children?.length) {
          this.openSubmenu(menu);
          setTimeout(() => {
            const subItems = this.elementRef.nativeElement.querySelectorAll(`.submenu.show .dropdown-item`);
            if (subItems.length > 0) {
              subItems[0].focus();
            }
          }, 100);
          event.preventDefault();
        }
        break;

      case 'Home':
        items[0].focus();
        event.preventDefault();
        break;

      case 'End':
        items[items.length - 1].focus();
        event.preventDefault();
        break;

      case 'Escape':
        this.closeMenu();
        this.elementRef.nativeElement.querySelector('.nav-link').focus();
        event.preventDefault();
        break;

      case 'Enter':
      case ' ':
        if (menu.children?.length) {
          this.openSubmenu(menu);
          setTimeout(() => {
            const subItems = this.elementRef.nativeElement.querySelectorAll(`.submenu.show .dropdown-item`);
            if (subItems.length > 0) {
              subItems[0].focus();
            }
          }, 100);
          event.preventDefault();
        }
        break;

      case 'Tab':
        if (!event.shiftKey && index === items.length - 1) {
          this.closeMenu();
        } else if (event.shiftKey && index === 0) {
          this.closeMenu();
        }
        break;
    }
  }

  navigateSubmenu(event: KeyboardEvent, parentIndex: number, childIndex: number): void {
    const menu = this.dropdownMenu[parentIndex];
    const subItems = this.elementRef.nativeElement.querySelectorAll(`.submenu.show .dropdown-item`);

    switch (event.key) {
      case 'ArrowDown':
        const nextIndex = (childIndex + 1) % subItems.length;
        subItems[nextIndex].focus();
        event.preventDefault();
        break;

      case 'ArrowUp':
        const prevIndex = (childIndex - 1 + subItems.length) % subItems.length;
        subItems[prevIndex].focus();
        event.preventDefault();
        break;

      case 'ArrowLeft':
        this.closeSubmenu(menu);
        const parentItems = this.elementRef.nativeElement.querySelectorAll('.dropdown-menu > .dropdown-item, .dropdown-menu .submenu-toggle');
        parentItems[parentIndex].focus();
        event.preventDefault();
        break;

      case 'Home':
        subItems[0].focus();
        event.preventDefault();
        break;

      case 'End':
        subItems[subItems.length - 1].focus();
        event.preventDefault();
        break;

      case 'Escape':
        this.closeSubmenu(menu);
        const items = this.elementRef.nativeElement.querySelectorAll('.dropdown-menu > .dropdown-item, .dropdown-menu .submenu-toggle');
        items[parentIndex].focus();
        event.preventDefault();
        break;
    }
  }

  onItemSelect(menu: Menu): void {
    this.itemSelected.emit(menu);
    this.closeMenu();
  }

  isActiveRoute(path: string): boolean {
    return this.router.isActive(path, {
      paths: 'exact',
      queryParams: 'exact',
      fragment: 'ignored',
      matrixParams: 'ignored'
    });
  }

  // Close when clicking outside
  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.closeMenu();
    }
  }
}
