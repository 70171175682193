import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Menu } from '../utils/menu';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Routes } from '../utils/router';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const navbar = document.querySelector('nav');
    if (navbar) {
      this.isSticky = navbar.classList.contains('sticky-top');
    }
  }

  @HostListener('window:resize', [])
  onWindowResize() {
    this.isLargeScreen = window.innerWidth > 1024;
  }

  industriesMenu: Menu[] = [];
  productsMenu: Menu[] = [];
  africaWeather_url: string='';
  isSticky: boolean = false;
  isLargeScreen: boolean = window.innerWidth > 1024;

  constructor(private router: Router,private deviceService: DeviceDetectorService, private route:Routes,private config:AppConfig) {
  }
  ngOnInit(): void {
    this.productsMenu= this.route.productsMenu;
    this.industriesMenu = this.route.industriesMenu;
    this.africaWeather_url= this.config.AFRICAWEATHER_PORTAL_WEB;
  }

  onNavigate() {
    window.open(this.africaWeather_url, "_blank");
  }

  closeMenu() {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();


    if (isMobile || isTablet) {
      const btnBar = document.getElementById('barMenuId');
      if (btnBar) {
        btnBar.click();
      }
    }
  }

  get logoSrc(): string {
    const logoColor = (!this.isLargeScreen || (this.isLargeScreen && this.isSticky)) ? 'black' : 'white';
    return `assets/images/logos/weblogo${logoColor}.png`;
  }
}
