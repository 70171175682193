import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'h5-p-tag',
  template: `
     <h5 class="text-primary-color">{{title}}</h5>
      <p>
       {{description}}
      </p>
  `
})

export class H5PComponent{
  @Input() title!: string;
  @Input() description!: string;
}
