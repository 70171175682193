import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-component-footer',
  templateUrl: './component-footer.component.html',
  styleUrls: ['./component-footer.component.css']
})
export class ComponentFooterComponent {
@Input('message') message:string | undefined;
}
