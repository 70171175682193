import { Component, Input, OnInit, HostListener, ElementRef, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'meduim-card-row',
  template: `
    <div class="africa-container">
      <ng-container *ngIf="image_first; else textFirst">
        <div class="image-content">
          <img [src]="image" [alt]="title" [style.height.px]="content_height">
        </div>
        <div class="text-content" #textContent>
        <dynamic-heading [title]="title" textColor="text-primary-color" level="h4"/>
          <!-- <span class="choose-title text-primary-color">{{ title }}</span> -->
          <p>{{ description }}</p>
        </div>
      </ng-container>
      <ng-template #textFirst>
        <div class="text-content" #textContent>
          <dynamic-heading [title]="title" textColor="text-primary-color" level="h4"/>
          <!-- <span class="choose-title text-primary-color">{{ title }}</span> -->
          <p>{{ description }}</p>
        </div>
        <div class="image-content">
          <img [src]="image" [alt]="title" [style.height.px]="content_height">
        </div>
      </ng-template>
    </div>
  `,
  styleUrls: ['./meduim-card-row.css']
})
export class MediumCardRowComponent implements OnInit, AfterViewInit {
  @Input() image_first = false;
  @Input() title: string='';
  @Input() image?: string;
  @Input() description?: string;
  content_height: number = 0;

  @ViewChild('textContent') textContent!: ElementRef;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.checkScreenSize(); // Set initial state
    console.log('Image first:', this.image_first);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.updateContentHeight();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkScreenSize(); // Update on resize
    setTimeout(() => {
      this.updateContentHeight(); // Check height on resize
    });
  }

  private checkScreenSize() {
    const mobileScreenWidth = 768;
    // Only override image_first if we're on mobile screens
    if (window.innerWidth < mobileScreenWidth) {
      this.image_first = false; // Always show image first on mobile
    }
    // On larger screens, respect the @Input() setting
  }

  private updateContentHeight() {
    if (this.textContent) {
      const height = this.textContent.nativeElement.offsetHeight;
      this.content_height = height;
    }
  }
}
