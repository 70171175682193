import { Menu } from "./menu";

export class Routes {
    industriesMenu: Menu[] = [
        { name: 'Mining', link: '/industries/mining' },
        { name: 'Insurance', link: '/industries/insurance' },
        { name: 'Schools and Universities', link: '/industries/schools-and-universities' },
        {name: 'Sporting Facilities',link: "/industries/sporting"
        },
      ];

      productsMenu: Menu[] = [
        { name: 'Mobile Application', link: '/products/application' },
        { name: 'API Solution', link: '/products/api-solution' },
        { name: 'Web-Based Portal', link: '/products/web-based-portal' },
        { name: 'Hardware Options', link: '/products/hardware-options' },
        { name: 'WeatherGuard', link: '/products/weatherguard' },
        {name:'Air Quality',link:'/products/air_quality',children:[
          {name:'Indoor Monitoring',link:'/products/air_quality/indoor-monitoring'},
          {name:'Outdoor Monitoring',link:'/products/air_quality/outdoor-monitoring'},
          {name:'Revolutionising Air Quality Monitoring',link:'/products/air_quality/revolutionising'}
        ]},

      ];

      servicesMenu: Menu[] = [
        { name: 'Additional Services', link: '/services/additional-services' },
        { name: 'Implementation and Support', link: '/services/implementation-and-support' },

        // { name: 'Warnings', link: '/warnings' },
        // { name: 'Storms', link: '/storms' },
        // { name: 'Maps', link: '/maps' },
        // { name: 'Graphs', link: '/graphs' },
      ]
}
