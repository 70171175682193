import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Feature } from 'src/app/shared/interfaces/feature/feature.interface';

@Component({
  selector: 'benefit-cards',
  template: `
    <div class="benefits-section" >
      <div class="benefits-grid">
        <div
          class="benefit-card" [ngClass]="{'centered': isCentered}"
          *ngFor="let detail of benefits"
          [ngStyle]="{'background-image': 'linear-gradient(rgba(5, 18, 25, 0.8), rgba(5, 18, 25, 0.8)), url(' + detail.imagePath + ')'}"
        >
          <h3>{{detail.title}}</h3>
          <p *ngIf="!detail.hasComponent">{{detail.description}}</p>
          <ng-container *ngIf="detail.hasComponent">
            <ng-container *ngComponentOutlet="detail.component"></ng-container>
          </ng-container>
          <div *ngIf="detail.component" [innerHTML]="detail.component"></div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./benefit-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BenefitCardComponent {
  @Input() benefits: Feature[] = [];
  @Input() isCentered: boolean = false;
}
