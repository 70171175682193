import { Component, Input } from '@angular/core';
import { slideInterfaces } from '../../../components/carousel/carousel.component';

@Component({
  selector: 'ui-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent {
  @Input()
  slide!: slideInterfaces;

}
