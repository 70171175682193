import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndustryLeftCardComponent } from './industry-card/industry-left-card.component';
import { IndustryRightCardComponent } from './industry-card/industry-right-card.component';
import { IndustriesFooterComponent } from './industries-footer/industries-footer.component';
import { HeaderComponent } from './header/header.component';
import { IndustryCardComponent } from './industry-card/industry-card.component';
import { InfoCardComponent } from './info-card/info-card.component';
import { RouterModule } from '@angular/router';




@NgModule({
  declarations: [
    IndustryLeftCardComponent,
    IndustryRightCardComponent,
    IndustriesFooterComponent,
    HeaderComponent,
    IndustryCardComponent,
    InfoCardComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports:[IndustryLeftCardComponent,IndustryRightCardComponent,IndustriesFooterComponent,HeaderComponent,IndustryCardComponent,InfoCardComponent
  ]
})
export class IndustrycomponentsModule { }
