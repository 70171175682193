import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'small-card-x',
  template: `
    <div class="industry-item">
            <img [src]="imagePath" alt="Mining Industry" class="industry-image">
            <div class="industry-text">
              <ng-content></ng-content>
            </div>
        </div>
  `,
styles: [`
.industry-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.industry-image {
  width: 150px;
  height: 100px;
  border-radius: 5px;
  margin-right: 20px;
  object-fit: cover;
}
.industry-text h3 {
  margin: 0;
  color: #0c2d57;
}
.industry-text p {
  margin: 5px 0 0;
  font-size: 16px;
  color: #444;
  line-height: 1.5;
}
    `]
})

export class SmallCardXComponent implements OnInit {
  @Input('imagePath') imagePath!: string;
  constructor() { }

  ngOnInit() { }
}
