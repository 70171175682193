import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'h3-p-tag',
  template: `
     <h3>{{title}}</h3>
      <p>
       {{description}}
      </p>
  `
})

export class H3PComponent{
  @Input() title!: string;
  @Input() description!: string;
}
