import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextsModule } from './texts/texts.module';
import { TemplatesModule } from './template/template.module';
import { ListsModule } from './lists/lists.module';
import { CardsModule } from './cards/cards.module';
import { ImagesModule } from './images/images..module';
import { SlidersModule } from './sliders/sliders.module';
import { ButtonsModule } from './buttons/buttons.module';
import { AWFormsModule } from './forms/forms.module';
import { BannersModule } from './banners/banners.module';
@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    TextsModule,
    CardsModule,
    TemplatesModule,
    ListsModule,
    ImagesModule,
    SlidersModule,
    ButtonsModule,
    AWFormsModule,
    BannersModule,
],
  exports: [
    TextsModule,
    CardsModule,
    TemplatesModule,
    ListsModule,
    ImagesModule,
    SlidersModule,
    ButtonsModule,
    AWFormsModule,
    BannersModule
  ]
})
export class UIModule { }
