import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'about-home',
  styleUrls: ['./about-home.component.css'],
  template: `
<div class="aboutTopic">
  <div class="left-column">
    <span class="highlighted-text">
      We are not just a weather solutions provider; we’re an insights leader, transforming complex data into intelligence that drives success.
    </span>
  </div>
  <div class="right-column">
    <p>
    AfricaWeather, a DTN affiliate, <strong>goes beyond forecasting</strong>—we harness data and IoT to deliver real-time insights that drive smarter decisions. Trusted by 25 of the top 40 JSE companies, we provide predictive analytics and alerts via APIs, apps, and web platforms. With our Total Lightning Network and global partnerships, we empower businesses to protect assets, optimize operations, and seize opportunities.
    </p>
  </div>
</div>
  `
})
export class AboutHomeComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
