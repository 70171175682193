import { AfterViewInit, Component } from '@angular/core';
import { Feature } from 'src/app/shared/interfaces/feature/feature.interface';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements AfterViewInit{

  benefits: Feature[] = [
    {
      title: 'Operational Efficiency',
      description: 'Minimise disruptions with precise forecasts.',
      imagePath: 'assets/images/clouds_bg.jpg'
    },
    {
      title: 'Safety Management',
      description: 'Protect assets with timely alerts.',
      imagePath: 'assets/images/clouds_bg.jpg'
    },
    {
      title: 'Informed Decision-Making',
      description: 'Make strategic decisions with real-time insights.',
      imagePath: 'assets/images/clouds_bg.jpg'
    },
    {
      title: 'Advanced Technology',
      description: 'Unlock deeper insights with cutting-edge tools.',
      imagePath: 'assets/images/clouds_bg.jpg'
    }
  ];
  ngAfterViewInit() {
    this.loadFacebookSDK();
  }

  loadFacebookSDK() {
    if ((window as any).FB) {
      (window as any).FB.XFBML.parse(); // Reinitialize if already loaded
    } else {
      const script = document.createElement('script');
      script.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
  }
}
