import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';

@NgModule({
  declarations: [
    ScrollToTopComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ScrollToTopComponent
  ]
})
export class ButtonsModule { }
