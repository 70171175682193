import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureListComponent } from './feature-list/feature-list.component';
import { TextsModule } from '../texts/texts.module';

@NgModule({
  declarations: [FeatureListComponent],
  imports: [CommonModule,TextsModule],
  exports: [FeatureListComponent]
})
export class ListsModule { }
