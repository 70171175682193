import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sliderthree',
  template: `
  <section class="hero-section hero-section-three">
        <div class="hero-content">
            <h1 class="header">What is AfricaWeather Insure?</h1>
            <p class="content-slider-three">AfricaWeather Insure is a severe weather and hail notification service that insurers can utilize to reduce the risk of hail damage and accident claims due to heavy fog amongst their policyholders.</p>
            <button class="btn" routerLink="/industries/insurance">Learn more</button>
        </div>
    </section>
  `,
   styleUrls: ['./slider.component.css']
})

export class SliderThreeComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
