import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'slidertwo',
  template: `
  <section class="hero-section hero-section-two">
        <div class="hero-content">
            <h1 class="header">How we help your business?</h1>
            <p class="content-slider-two">We monitor and notify you to take action our lightning warning system monitors your location 24/7 and we notify you when there is a danger condition.</p>
            <button class="btn" routerLink="/industries/mining">Learn more</button>
        </div>
    </section>
  `,
   styleUrls: ['./slider.component.css']
})

export class SliderTwoComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
