import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { H2PComponent } from './h2-p-tag/h2-p-tag.component';
import { H3PComponent } from './h3-p-tag/h3-p-tag.component';
import { H4PComponent } from './h4-p-tag/h4-p-tag.component';
import { H5PComponent } from './h5-p-tag/h5-p-tag.component';
import { H6PComponent } from './h6-p-tag/h6-p-tag.component';
import { PTitleComponent } from './p-title/p-title.component';
import { DynamicHeadingComponent } from './dynamic-heading/dynamic-heading.component';


@NgModule({
  declarations: [
    H4PComponent,
    H3PComponent,
    H2PComponent,
    H5PComponent,
    H6PComponent,
    PTitleComponent,
    DynamicHeadingComponent
  ],
  imports: [
    CommonModule,
],
  exports: [
    H4PComponent,
    H3PComponent,
    H2PComponent,
    H5PComponent,
    H6PComponent,
    PTitleComponent,
    DynamicHeadingComponent
  ]
})
export class TextsModule { }
