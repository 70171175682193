import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { V1RoutingModule } from './v1-routing.module';
import { V1Component } from './v1.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PagesModule } from './pages/pages.module';


@NgModule({
  declarations: [
    V1Component
  ],
  imports: [
    V1RoutingModule,
    PagesModule,
    SharedModule
  ]
})
export class V1Module { }
