<!-- <header class="why-us-header py-5">
  <div class="row py-5">
    <h1>Insurance</h1>
    <p>At AfricaWeather, we can't control the weather, but we help you manage it and protect your business from its risks.</p>
  </div>
</header> -->

<div class="container-fluid bg-primary py-5 why-us-header">
  <div class="row py-5">
      <div class="col-12 pt-lg-5 mt-lg-5">
          <h1 class="why-us-title">Insurance</h1>
          <p>At AfricaWeather, we can't control the
            weather, but we help you manage it and
            protect your business from its risks.</p>
      </div>
  </div>
</div>

<div class="why-us-container">
  <section class="main-content">
    <h2>Weather Risk Management for the Insurance Industry</h2>
    <p>In the insurance industry, mitigating risk is essential, and severe weather can significantly impact profitability.
      AfricaWeather provides tailored insights that help insurers make smarter, data-driven decisions to reduce claims,
      protect policyholders, and enhance operational efficiency. Our products help insurers reduce risk exposure by
      monitoring key variables like wind, temperature, fog, hail, and lightning. With real-time, customisable weather
      alerts, insurers can inform clients of impending threats, preventing damage and reducing claims.
      </p>
    <p>
      AfricaWeather’s insights into long-term climate trends also help insurers with future risk planning, while proactive
protection builds client trust and loyalty. Insurers also benefit from robust data that supports regulatory
compliance, optimising both risk management and overall business performance.
    </p>
  </section>

    <div class="image-container">
        <div class="image-item" style="background-image: url('assets/newlook/images/1.jpg');"></div>
        <div class="image-item middle-image" style="background-image: url('assets/newlook/images/2.jpg');"></div>
        <div class="image-item" style="background-image: url('assets/newlook/images/3.jpg');"></div>
    </div>

  <section class="details">
    <h2>Minimise Insurance Claims Through Early Weather Warnings</h2>
    <p>Weather affects insurers and their clients, from household claims due to storms to vehicle damages from hail.<br>
      AfricaWeather’s services allow insurers to:</p>
    <ul>
      <li class="points"><strong>Reduce Risk Exposure:</strong> By monitoring critical weather variables like wind, temperature, hail, and lightning,
        insurers can better prepare and protect their clients' lives and assets.</li>
      <li class="points"><strong>Maximise Cost Efficiency:</strong> Preventing even one major claim can offset the entire investment in AfricaWeather’s
        services, resulting in substantial savings.</li>
      <li class="points"><strong>Proactive Alerts:</strong>  Insurers receive real-time, location-specific SMS alerts for severe weather, allowing them to
        inform clients of upcoming threats and reduce potential damage before it occurs. Alerts are sent 30 minutes
        before storms, with a dashboard offering suburb-level insights on affected policyholders and event analytics.
        We also offer weather alerts via WhatsApp, using text, images, and videos to enhance engagement.</li>
      <li class="points"><strong>Customised Alerts for Enhanced Relevance:</strong> AfricaWeather’s alerting system can be tailored to specific
        locations and policy types, ensuring that warnings are relevant to the insurer's portfolio and reduce
        unnecessary notifications.</li>
      <li class="points"><strong>Post-Event Analysis for Claims Verification:</strong> Our detailed post-event weather reports offer precise data for
        validating claims, helping insurers streamline the claims process and prevent fraud.
        </li>
    </ul>
  </section>
</div>

<footer class="cta-section">
  <h5>Powered by AfricaWeather’s data-driven insights, insurers can better manage
    risk and optimise their operations. Find out more about how we can help you
    increase safety and optimise your operations</h5>
  <button routerLink="/contact">Contact Us</button>
</footer>
