import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';import { LargeCardComponent } from './large-card/large-card.component';
import { SmallCardXComponent } from './small-card-x/small-card-x.component';
import { SmallCardComponent } from './small-card/small-card.component';
import { TemplatesModule } from '../template/template.module';
import { TextsModule } from '../texts/texts.module';
import { MediumCardRowComponent } from './meduim-card-row/meduim-card-row.component';
import { MainCardComponent } from './main-card/main-card.component';
import { RouterModule } from '@angular/router';
import { BenefitCardComponent } from './benefit-card/benefit-card.component';

@NgModule({
  declarations: [
    LargeCardComponent,
    SmallCardComponent,
    SmallCardXComponent,
    MediumCardRowComponent,
    MainCardComponent,
    BenefitCardComponent
  ],
  imports: [
    CommonModule,
    TemplatesModule,
    TextsModule,
    RouterModule
],
  exports: [
    LargeCardComponent,
    SmallCardComponent,
    SmallCardXComponent,
    MediumCardRowComponent,
    MainCardComponent,
    BenefitCardComponent

  ]
})
export class CardsModule { }
