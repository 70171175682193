import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AboutComponent } from './pages/about/about.component';
import { HomeComponent } from './pages/home/home.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { AppConfig } from './app.config';
import { environment } from 'src/environments/environment';
import { StormwarningsComponent } from './stormwarnings/stormwarnings.component';
import { MobileAlertComponent } from './pages/alerts/mobile-alert/mobile-alert.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IndustrycomponentsModule } from './pages/industries/components/Industrtcomponent.module';
import { UIModule } from './shared/ui-components/us-component.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './shared/footer/footer.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { LoadingModule } from './shared/loader/loading.module';
@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    HomeComponent,
    FooterComponent,
    ContactComponent,
    PrivacyPolicyComponent,
    StormwarningsComponent,
    MobileAlertComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    IndustrycomponentsModule,
    UIModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    RecaptchaModule,  // Import reCAPTCHA module
    RecaptchaFormsModule, // If using reactive forms
    LoadingModule
  ],
  providers: [AppConfig,{ provide: 'PORTAL_URL', useValue: environment.portal_url },],
  bootstrap: [AppComponent]
})
export class AppModule { }
