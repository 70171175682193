import { Component, Input, Output,EventEmitter } from '@angular/core';
import { Menu } from '../../utils/menu';

@Component({
  selector: 'nav-dropdown',
  template: `
    <div class="nav-item dropdown" (mouseenter)="openMenu()" (mouseleave)="closeMenu()">
      <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" (keydown)="handleKeydown($event)">{{ title }}</a>
      <div class="dropdown-menu m-0" [class.show]="isOpen" [ngClass]="{'rounded-corners': isOpen}">
        <a
          *ngFor="let menu of dropdownMenu; let i = index" (click)="closeMenu()"
          [routerLink]="menu?.link"
          class="dropdown-item"
          tabindex="0"
          (keydown)="navigateDropdown($event, i)"
          >{{ menu?.name }}</a
        >
      </div>
    </div>
  `,
  styles: [`
    .rounded-corners {
      border-radius: 10px; /* Adjust the value as needed */
    }
  `]
})
export class NavDropdownComponent {
  @Input()
  dropdownMenu!: Menu[];
  @Input('title') title!: string;

  @Output() closeBarMenu = new EventEmitter<void>();

  isOpen = false;

  openMenu(): void {
    this.isOpen = true;
  }

  closeMenu(): void {
    this.isOpen = false;
    this.closeBarMenu.emit();
  }

  handleKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      this.openMenu();
      event.preventDefault();
    }
  }

  navigateDropdown(event: KeyboardEvent, index: number): void {
    const items = document.querySelectorAll('.dropdown-item');
    if (event.key === 'ArrowDown') {
      const nextIndex = (index + 1) % items.length;
      (items[nextIndex] as HTMLElement).focus();
      event.preventDefault();
    } else if (event.key === 'ArrowUp') {
      const prevIndex = (index - 1 + items.length) % items.length;
      (items[prevIndex] as HTMLElement).focus();
      event.preventDefault();
    } else if (event.key === 'Escape') {
      this.closeMenu();
    }
  }
}
