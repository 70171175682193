import { NgModule } from '@angular/core';
import { AlertBannerComponent } from './alert-banner/alert-banner.component';
import { CommonModule } from '@angular/common';
import { AlertService } from './alert.service';

@NgModule({
  imports: [CommonModule],
  exports: [AlertBannerComponent],
  declarations: [AlertBannerComponent],
  providers: [AlertService],
})
export class BannersModule { }
