import { Component } from '@angular/core';

@Component({
  selector: 'app-why-us',
  templateUrl: './why-us.component.html',
  styleUrls: ['./why-us.component.css']
})
export class WhyUsComponent {
  features = [
    {
      imgSrc: 'assets/images/icons/industries/Data Driven Solutions.png',
      imgAlt: 'Data-Driven Solutions',
      title: 'Data-Driven Solutions',
      description: `As Africa’s leading commercial weather service provider, AfricaWeather offers intuitive,
                    data-driven solutions that safeguard both people and assets while directly impacting client profitability.`
    },
    {
      imgSrc: 'assets/images/icons/industries/expert tech.png',
      imgAlt: 'Expert Technology',
      title: 'Expert Technology',
      description: `By expertly managing weather risks, our technology minimises operational disruptions and prioritises safety,
                    allowing clients to make strategic, time-sensitive decisions with confidence.`
    },
    {
      imgSrc: 'assets/images/icons/industries/Industry Leading Accuracy.png',
      imgAlt: 'Industry-Leading Accuracy',
      title: 'Industry-Leading Accuracy',
      description: `Our industry-leading accuracy empowers businesses to stay resilient, proactive, and well-prepared for any
                    weather scenario, reinforcing our commitment to delivering value across every sector we serve.`
    }
  ];
}
