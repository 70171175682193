import { Component, OnInit } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { environment } from 'src/environments/environment';
import { isDevMode } from '@angular/core';
import { MainCardData } from 'src/app/shared/interfaces/cards/main-card.interface';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  constructor(private config: AppConfig){}

 register_url : string =this.config.AFRICAWEATHER_REGISTER_URL;
 portal_url:string = this.config.AFRICAWEATHER_PORTAL_WEB;

 ngOnInit(): void {
}

mainCardDataList: MainCardData[] = [
  {
    headerText: 'Transforming weather data into actionable insights',
    descriptionText: "At AfricaWeather, we can't control the weather, but we help you manage it and protect your business from its risks.",
    buttonText: 'Learn More',
    buttonLink: '/about-us',
    backgroundImage: 'assets/images/banner.jpg'
  },
  {
    headerText: 'How we help your business?',
    descriptionText: 'We monitor and notify you to take action our lightning warning system monitors your location 24/7 and we notify you when there is a danger condition.',
    buttonText: 'Learn More',
    buttonLink: '/industries/mining',
    backgroundImage: 'assets/newlook/images/background/clearcloud.jpg'
  },
  {
    headerText: 'What is AfricaWeather Insure?',
    descriptionText: 'AfricaWeather Insure is a severe weather and hail notification service that insurers can utilize to reduce the risk of hail damage and accident claims due to heavy fog amongst their policyholders.',
    buttonText: 'Learn More',
    buttonLink: '/industries/insurance',
    backgroundImage: 'assets/newlook/images/background/rain.jpg'
  },
  {
    headerText: 'Introducing Our User-Centric Weather App: Stay Informed, Anywhere, Anytime',
    descriptionText: "Our user-centric weather app is designed with you in mind, providing easy access to weather forecasts and real-time storm tracking while you're on the move.",
    buttonText: 'Learn More',
    buttonLink: '/products/application',
    backgroundImage: 'assets/newlook/images/background/cloudy.jpg'
  }
];
}
