import { Component } from '@angular/core';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent {

  constructor(private config:AppConfig){}

  register_url=this.config.AFRICAWEATHER_REGISTER_URL;
}
