import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookies-notice',
  templateUrl: './cookies-notice.component.html',
  styleUrls: ['./cookies-notice.component.css'],
})
export class CookiesNoticeComponent implements OnInit {
  cookiesAccepted: boolean = false;
  cookiesRejected: boolean = false;

  constructor(private cookieService: CookieService) {}

  ngOnInit(): void {
    this.checkCookiesConsent();
  }

  checkCookiesConsent(): void {
    const accepted = localStorage.getItem('cookiesAccepted') === 'true';
    const rejected = localStorage.getItem('cookiesAccepted') === 'false';

    if (accepted) {
      this.cookiesAccepted = true;
    } else if (rejected) {
      this.cookiesRejected = true;
    }
  }

  acceptCookies(): void {
    this.cookieService.set('cookiesAccepted', 'true', { path: '/' });
    localStorage.setItem('cookiesAccepted', 'true');
    this.cookiesAccepted = true;
    this.cookiesRejected = false;
  }

  rejectCookies(): void {
    this.cookieService.set('cookiesAccepted', 'false', { path: '/' });
    localStorage.setItem('cookiesAccepted', 'false');
    this.cookiesRejected = true;
    this.cookiesAccepted = false;
  }
}
