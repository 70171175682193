<div *ngIf="!cookiesAccepted" class="alert alert-primary alert-dismissible col-xs-12 footer-cookie-notice" role="alert">
    <div class="row">
      <div class="col-xs-12">
        <h2 class="text-left">Cookie Notice</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <p>
          Please note that we use cookies to offer you a better user experience, analyze site traffic, and better serve advertising.
          By continuing to use this website, you consent to the use of cookies in accordance with our
          <a routerLink="/legal/privacypolicy" rel="noreferrer">Privacy Policy</a>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <button type="button" class="btn btn-primary close-privacy-notice" (click)="acceptCookies()" aria-label="Close">
          Got it
        </button>
      </div>
    </div>
  </div>
  