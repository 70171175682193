import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-alert',
  templateUrl: './mobile-alert.component.html',
  styleUrls: ['./mobile-alert.component.css']
})
export class MobileAlertComponent {

}
