import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'h3-p-tag',
  template: `

  <span class="text-primary-color">{{title}}</span>

  <p>
   {{description}}
  </p>
`,
styles:[
`
span {
font-size: 42px;
line-height: 1.2;
}`
]
})

export class H3PComponent{
  @Input() title!: string;
  @Input() description!: string;
}
