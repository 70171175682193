<div id="header-carousel" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
      <div class="carousel-item active">
      <into-header></into-header>
      </div>
      <div class="carousel-item">
      <slidertwo></slidertwo>
      </div>
      <div class="carousel-item">
      <sliderthree></sliderthree>
      </div>
      <div class="carousel-item">
      <sliderfour></sliderfour>
      </div>

  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#header-carousel"
      data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#header-carousel"
      data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
  </button>
</div>
