import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderTwoComponent } from './slidertwo.component';
import { SliderThreeComponent } from './sliderthree.component';
import { SliderFourComponent } from './sliderfour.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [SliderTwoComponent,SliderThreeComponent,SliderFourComponent],
  imports: [CommonModule,RouterModule],
  exports: [SliderTwoComponent,SliderThreeComponent,SliderFourComponent]
})
export class SliderModule {}
