import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CarouselComponent } from './carousel/carousel.component';
import { HomeSliderComponent } from './home-slider/home-slider.component';
import { CardsModule } from "../cards/cards.module";

@NgModule({
  declarations: [CarouselComponent,HomeSliderComponent],
  imports: [
    CommonModule,
    RouterModule,
    RouterModule,
    CardsModule
],
  exports: [CarouselComponent,HomeSliderComponent]
})
export class SlidersModule { }
