<header title="Contact Us" description="Do you have a query or would like to learn
more about our solutions?" background_image_path="assets/images/covers/contact_us.png"
></header>

<div class="main-container py-5 wow fadeInUp" data-wow-delay="0.1s">
  <dynamic-heading title="How can we assist you?"
  description="We are committed to providing you with the best customer service" level="h2"/>
  <benefit-cards [benefits]="contactDetails" [isCentered]="true"/>
  <div class="contact-container">
    <div class="contact-form">
      <dynamic-heading title="Send us a message" level="h3"/>
      <form [formGroup]="contactForm" (ngSubmit)="submitForm()">

        <!-- Full Name -->
        <label for="name">Full Name</label>
        <input id="name" type="text" formControlName="name" required>
        <small *ngIf="contactForm.controls['name'].invalid && contactForm.controls['name'].touched">Name is required</small>

        <label for="email">Contact Number</label>
        <input id="contact" type="text" formControlName="contact" required>
        <small *ngIf="contactForm.controls['contact'].invalid && contactForm.controls['contact'].touched">Enter a valid contact number</small>

        <!-- Email -->
        <label for="email">Email Address</label>
        <input id="email" type="email" formControlName="email" required>
        <small *ngIf="contactForm.controls['email'].invalid && contactForm.controls['email'].touched">Enter a valid email</small>

        <!-- Contact Type -->
        <label for="contactType">Are you an Individual or a Company?</label>
        <select id="contactType" formControlName="contactType" required>
          <option value="" disabled selected>Select</option>
          <option value="individual">Individual</option>
          <option value="company">Company</option>
        </select>
        <small *ngIf="contactForm.controls['contactType'].invalid && contactForm.controls['contactType'].touched">Please select an option</small>

        <!-- Company Fields (Shown Dynamically) -->
        <div *ngIf="isCompany">
          <label for="companyName">Company Name</label>
          <input id="companyName" type="text" formControlName="companyName">
          <label for="companyName">Company Email Address</label>
          <input id="companyEmail" type="email" formControlName="companyEmail">
          <label for="companyName">Company Contact Number</label>
          <input id="companyContact" type="text" formControlName="companyContact">
          <label for="companySize">Company Size</label>
          <select id="companySize" formControlName="companySize">
            <option value="1-10">1-10 Employees</option>
            <option value="11-50">11-50 Employees</option>
            <option value="51-200">51-200 Employees</option>
            <option value="201+">201+ Employees</option>
          </select>
        </div>

        <!-- Reason for Contact -->
        <label for="reason">Reason for Contact</label>
        <select id="reason" formControlName="reason">
          <option value="general">General Inquiry</option>
          <option value="forecast">Weather Forecast Issue</option>
          <option value="support">Technical Support</option>
          <option value="business">Business Partnership</option>
          <option value="data-request">Weather Data &amp; API Request</option>
          <option value="advertising">Advertising &amp; Sponsorship</option>
          <option value="career">Career Opportunities</option>
          <option value="feedback">Feedback &amp; Suggestions</option>
          <option value="complaint">Complaint</option>
          <option value="other">Other</option>
        </select>


        <!-- Message -->
        <label for="message">Message</label>
        <textarea id="message" formControlName="message" rows="4"></textarea>

        <re-captcha
        [siteKey]="site_key"
        (resolved)="handleCaptchaResponse($event)">
      </re-captcha>
        <!-- Submit Button -->
        <button type="submit" [disabled]="contactForm.invalid" [ngClass]="{'btn-gray': contactForm.invalid, 'btn-blue': contactForm.valid}">Send Message</button>
      </form>
    </div>

    <!-- Image Section -->
    <div class="contact-image embed-responsive embed-responsive-16by9">
      <iframe class="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3584.240664260633!2d28.08467891570667!3d-26.058390079540747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9573910f33500f%3A0xd40deba5d7a28c9f!2sThe%20Woodlands%20Office%20Park!5e0!3m2!1sen!2sza!4v1715845076128!5m2!1sen!2sza" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  </div>
</div>

