import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsuranceComponent } from './products/insurance/insurance.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    InsuranceComponent
  ],
  imports: [CommonModule, SharedModule],
  exports: [InsuranceComponent]
})
export class VOnepagesModule {}
