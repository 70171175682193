<header-nav title="About Us" link="/about"></header-nav>

<div class="page-content-holder">
  <div id="content" class="plain-content">
      <div class="content-section">
        <div class="text-content">
            <h1>AfricaWeather: Transforming Weather Data into Actionable Insights</h1>
            <p>
                AfricaWeather, a DTN-affiliated company, goes beyond weather forecasting – we deliver actionable insights that help
                businesses manage risk, optimise operations, and make smarter decisions. Leveraging advanced weather intelligence,
                we aggregate, model, and transform data into insights that empower over 15 industries.
            </p>
            <p>
                As Africa’s leading weather insights provider, trusted by 25 of the top 40 JSE companies, we offer real-time alerts
                and predictive analytics through APIs, mobile apps, and web platforms. Powered by our Total Lightning Network and
                partnerships with SAWS and global providers, we provide the data businesses need to protect assets, streamline workflows,
                and seize opportunities.
            </p>
            <h2>Empowering Businesses with Weather Intelligence</h2>
            <p>
                We use AI, machine learning, and advanced modelling to deliver four key benefits:
            </p>
            <ul>
                <li><strong>Operational Efficiency</strong> – Minimise disruptions with precise forecasts.</li>
                <li><strong>Safety Management</strong> – Protect assets with timely alerts.</li>
                <li><strong>Informed Decision-Making</strong> – Make strategic decisions with real-time insights.</li>
                <li><strong>Advanced Technology</strong> – Unlock deeper insights with cutting-edge tools.</li>
            </ul>
            <p>
                At AfricaWeather, we are not just a weather company; we are an insights company, turning complex data into intelligence
                that drives success.
            </p>
        </div>
    </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6 mb-4">
            <div class="card">
              <img class="img-fluid" src="assets/images/partners/WeatherZone.png" alt="WeatherZone" class="card-image">
            </div>
          </div>
          <div class="col-lg-3 col-md-6 mb-4">
           <div class="card">
            <img class="img-fluid" src="assets/images/partners/DTN.png" alt="DTN" class="card-image">
           </div>
          </div>
          <div class="col-lg-3 col-md-6 mb-4">
            <div class="card">
              <img class="img-fluid" src="assets/images/partners/SAWS.png" alt="SAWS" class="card-image">
            </div>
          </div>
          <div class="col-lg-3 col-md-6 mb-4">
            <div class="card">
              <img class="img-fluid" src="assets/images/partners/EarthNetworks.png" class="card-image" alt="EarthNetworks">
            </div>
          </div>
        </div>
      </div>

  </div>
</div>
