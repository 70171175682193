import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'info-card',
  template: `
  <div class="info-card-container">
    <div class="info-card" *ngFor="let card of cards" [ngClass]="{'row-layout': isRow, 'column-layout': !isRow}">
      <div class="icon">
        <img [src]="card.iconPath || 'assets/default-icon.svg'" alt="Icon" />
      </div>
      <div class="content">
        <h3>{{ card.title }}</h3>
        <p>{{ card.description }}</p>
      </div>
    </div>
  </div>
  `,
  styleUrls:['./info-card.component.css']
})

export class InfoCardComponent implements OnInit {
  @Input() cards: { title: string; description: string; iconPath?: string }[] = [];
  @Input() isRow: boolean = true;

  constructor() { }

  ngOnInit() { }
}
