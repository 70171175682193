import { Component, Input } from '@angular/core';

@Component({
  selector: 'dynamic-heading',
  template: `
    <span [ngClass]="'heading-' + level + ' ' + textColor">{{ title }}</span>
    <p [ngClass]="textColor">{{ description }}</p>
  `,
  styles: [
    `
    span {
      font-weight: normal; /* No bold */
      line-height: 1.2;
      display: block;
      margin-bottom: 8px;
    }

    /* Dynamic font sizes */
    .heading-h1 { font-size: 56px; }
    .heading-h2 { font-size: 48px; }
    .heading-h3 { font-size: 40px; }
    .heading-h4 { font-size: 32px; }
    .heading-h5 { font-size: 24px; }
    .heading-h6 { font-size: 18px; }

    p {
      font-size: 16px;
      color: #666;
      line-height: 1.5;
    }

    /* Responsive font adjustments */
    @media (max-width: 1200px) {
      .heading-h1 { font-size: 48px; }
      .heading-h2 { font-size: 40px; }
      .heading-h3 { font-size: 36px; }
      .heading-h4 { font-size: 28px; }
      .heading-h5 { font-size: 22px; }
      .heading-h6 { font-size: 18px; }
    }

    @media (max-width: 768px) {
      .heading-h1 { font-size: 40px; }
      .heading-h2 { font-size: 36px; }
      .heading-h3 { font-size: 30px; }
      .heading-h4 { font-size: 24px; }
      .heading-h5 { font-size: 20px; }
      .heading-h6 { font-size: 16px; }
    }

    @media (max-width: 480px) {
      .heading-h1 { font-size: 32px; }
      .heading-h2 { font-size: 28px; }
      .heading-h3 { font-size: 24px; }
      .heading-h4 { font-size: 20px; }
      .heading-h5 { font-size: 18px; }
      .heading-h6 { font-size: 16px; }
    }
    `
  ]
})
export class DynamicHeadingComponent {
  @Input() title!: string;
  @Input() description!: string;
  @Input() level: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' = 'h2';
  @Input() textColor: string = 'text-primary-color';
}
