import { Component, Input, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'industry-card',
  template: `
  <div class="content">
      <ng-container *ngIf="image_first; else textFirst">
        <img
          [src]="image"
          [alt]="title"
          class="content-image"
        />
        <div class="content-text">
          <h2>{{title}}</h2>
          <p>{{description}}</p>
          <ng-content></ng-content>
        </div>
      </ng-container>
      <ng-template #textFirst>
        <div class="content-text">
          <h2>{{title}}</h2>
          <p>{{description}}</p>
          <ng-content></ng-content>
        </div>
        <img
          [src]="image"
          [alt]="title"
          class="content-image"
        />
      </ng-template>
    </div>
  `,
  styleUrls:['./industry.css']
})

export class IndustryCardComponent implements OnInit {
  @Input('image_first') image_first = false;
  @Input('title') title: string | undefined;
  @Input('image') image: string | undefined;
  @Input('description') description: string | undefined;

  ngOnInit() {

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    // this.checkScreenSize();
    // console.log(`Is the Image first? ${this.image_first}`)
  }

  private checkScreenSize() {
   // console.log(window.innerWidth);
    const mobileScreenWidth = 768;
    this.image_first = (window.innerWidth >= mobileScreenWidth);
  }
}
