import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'solutions-section',
  styleUrls: ['./solutions-section.component.css'],
  template: `
  <div class="solutions-container">
  <h2>SOLUTIONS</h2>
  <div class="solution-cards">
    <div class="solution-card">
    <a [routerLink]="['/industries/mining']">
      <img src="assets/newlook/images/AfricaWeather - Mining.jpg" alt="Mining Industry">
      <h3>MINING</h3>
      <p>We provide actionable insights that help mining operations mitigate risk, reduce downtime, and optimize production efficiency.</p>
    </a>
    </div>
    <div class="solution-card">
      <a [routerLink]="['/industries/insurance']">
        <img src="assets/newlook/images/screen_2x (4).jpg" alt="Insurance Industry">
        <h3>INSURANCE</h3>
        <p>Minimize risk exposure and safeguard policyholders with precise weather intelligence.</p>
      </a>
    </div>
    <div class="solution-card">
    <a [routerLink]="['/industries/schools-and-universities']">
      <img src="assets/newlook/images/s2 (6).jpg" alt="Schools & Universities">
      <h3>SCHOOLS & UNIVERSITIES</h3>
      <p>Enhancing operational efficiency and safety with reliable weather forecasting.</p>
    </a>
    </div>
    <div class="solution-card">
    <a [routerLink]="['/industries/sporting']">
      <img src="assets/newlook/images/golfimg.jpg" alt="Sporting Facilities">
      <h3>SPORTING FACILITIES</h3>
      <p>Optimizing course management and player safety with advanced weather intelligence.</p>
    </a>
    </div>
  </div>
</div>

  `
})

export class SolutionsContainerComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
