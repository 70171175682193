import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookies-notice',
  templateUrl: './cookies-notice.component.html',
  styleUrls: ['./cookies-notice.component.css'],
})
export class CookiesNoticeComponent implements OnInit {
  cookiesAccepted: boolean = false;

  constructor(private cookieService: CookieService) {}

  ngOnInit(): void {
    this.checkCookiesConsent();
  }

 checkCookiesConsent(): void {
    const cookiesAccepted = this.cookieService.get('cookiesAccepted') === 'true';
    this.cookiesAccepted = cookiesAccepted;
  }

  acceptCookies(): void {
    this.cookieService.set('cookiesAccepted', 'true');
    this.cookiesAccepted = true;
  }
}
