import { Component, Input } from '@angular/core';

@Component({
  selector: 'info-card',
  template: `
    <div class="info-card-container">
      <div class="info-card" *ngFor="let card of cards">
        <div class="text-content">
          <h4>{{ card.title }}</h4>
          <p>{{ card.description }}</p>
        </div>
        <div class="image-container">
          <img [src]="card.iconPath" alt="Icon" class="half-moon-img" />
        </div>
      </div>
    </div>
  `,
  styles: [`
    .info-card-container {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      flex-wrap: wrap;
    }

    .info-card {
      flex: 1;
      min-width: 300px;
      max-width: 400px;
      background: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      position: relative;
      overflow: hidden;
    }

    .info-card:hover {
      transform: translateY(-5px);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    }

    .text-content {
      flex: 1;
    }

    .text-content h4 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    .text-content p {
      font-size: 14px;
      color: #333;
    }

    .image-container {
      position: absolute;
      right: 0;
      top: 0;
      width: 120px;
      height: 120px;
      overflow: hidden;
    }

    .half-moon-img {
      width: 100%;
      height: auto;
      border-radius: 50%;
      clip-path: inset(0 50% 0 0);
    }

    @media (max-width: 768px) {
      .info-card-container {
        flex-direction: column;
        align-items: center;
      }

      .info-card {
        width: 100%;
        max-width: 90%;
      }
    }
  `]
})
export class InfoCardComponent {
  @Input() cards: any[] = [];
}
