import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

export interface AlertConfig {
  message: string;
  type: 'error' | 'warning' | 'info' | 'success';
  duration?: number; // in milliseconds, optional
  showIcon?: boolean;
  dismissible?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private alertSubject = new Subject<AlertConfig | null>();

  constructor() {}

  // Observable that components can subscribe to
  getAlerts(): Observable<AlertConfig | null> {
    return this.alertSubject.asObservable();
  }

  // Show an alert
  showAlert(config: AlertConfig): void {
    this.alertSubject.next(config);

    // Auto-dismiss after duration if specified
    if (config.duration) {
      setTimeout(() => {
        this.dismissAlert();
      }, config.duration);
    }
  }

  // Show error alert with default settings
  showError(message: string, duration: number = 5000): void {
    this.showAlert({
      message,
      type: 'error',
      duration,
      showIcon: true,
      dismissible: true
    });
  }

  // Show warning alert with default settings
  showWarning(message: string, duration: number = 5000): void {
    this.showAlert({
      message,
      type: 'warning',
      duration,
      showIcon: true,
      dismissible: true
    });
  }

  // Show info alert with default settings
  showInfo(message: string, duration: number = 5000): void {
    this.showAlert({
      message,
      type: 'info',
      duration,
      showIcon: true,
      dismissible: true
    });
  }

  // Show success alert with default settings
  showSuccess(message: string, duration: number = 5000): void {
    this.showAlert({
      message,
      type: 'success',
      duration,
      showIcon: true,
      dismissible: true
    });
  }

  // Dismiss current alert
  dismissAlert(): void {
    this.alertSubject.next(null);
  }
}
