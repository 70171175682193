import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'technology-section',
  styleUrls: ['./technology-section.component.css'],
  template: `
    <div class="techsection">
      <div class="content-title">
        <h1>Our Technology</h1>
      </div>
      <div class="section">
        <div class="content-left"></div>
        <div class="content-right">
          <p>
            AfricaWeather harnesses cutting-edge meteorological technology,
            blending high-resolution weather data with advanced predictive
            analytics to deliver real-time, industry-specific insights across
            Africa. Our proprietary Total Lightning Network, on-site weather
            stations, and machine learning-powered OptiCast forecasting system
            equip clients with the precision to make proactive, informed
            decisions.
          </p>
          <p>
            Customisable, sector-focused dashboards integrate seamlessly with
            client operations, while real-time, location-specific alerts and
            automated responses – such as our Audio-Visual Alerting System –
            enhance safety and operational continuity. With a robust platform
            built to withstand Africa’s diverse climates, AfricaWeather ensures
            businesses remain prepared and resilient, no matter the forecast.
          </p>
        </div>
      </div>
      <div class="content-button">
        <a href="#" class="learn-more-btn">LEARN MORE</a>
      </div>
    </div>
  `,
})
export class TechnologySectionComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
