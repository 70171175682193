import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'side-by-side-card',
  styleUrls: ['./side-by-side-card.css'],
  template: `
<div class="aboutTopic">
  <div class="left-column">
    <span class="highlighted-text">
      {{ title }}
    </span>
  </div>
  <div class="right-column">
    <p>
      {{ description }}
    </p>
  </div>
</div>
  `
})
export class SideBySideCardComponent {
  @Input() title: string = 'About Us';
  @Input() description: string = 'We are not just a weather solutions provider; we’re an insights leader, transforming complex data into intelligence that drives success.';
}
