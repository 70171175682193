<header-nav title="Contact Us" link="/contact"></header-nav>


<!-- Contact Start -->
<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container py-5">
        <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px;">
            <h5 class="fw-bold text-primary text-uppercase">Contact Us</h5>
            <h1 class="mb-0">If You Have Any Query, Feel Free To Contact Us</h1>
        </div>

        <div class="container">
            <div class="row g-5">
              <div class="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
                <div class="ps-lg-4 row g-3">
                  <div class="contact-item">
                    <h5 class="mb-2">Call to ask any question</h5>
                    <h4 class="text-primary mb-0">+27 (0)11-300 7480</h4>
                  </div>
                </div>
                <div class="ps-lg-4 row g-3">
                  <div class="contact-item">
                    <h5>Email to Get a Free Quote</h5>
                    <h4 class="text-primary"><strong>Sales: </strong> <a href="mailto:sales@africaweather.com">sales@africaweather.com</a></h4>
                    <h4 class="text-primary"><strong>Technical Support: </strong> <a href="mailto:itsupport@africaweather.com">itsupport@africaweather.com</a></h4>
                    <h4 class="text-primary"><strong>Finance: </strong> <a href="mailto:accounts@africaweather.com">accounts@africaweather.com</a></h4>
                  </div>
                </div>
                <div class="ps-lg-4 row g-3">
                  <div class="contact-item">
                    <h5 class="mb-2">Visit our office</h5>
                    <h4 class="text-primary mb-0">First Floor, Building 15, The Woodlands Office Park, 20 Woodlands Drive, Woodmead,2080</h4>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 wow slideInUp" data-wow-delay="0.6s">
                <div class="embed-responsive embed-responsive-16by9">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3584.240664260633!2d28.08467891570667!3d-26.058390079540747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9573910f33500f%3A0xd40deba5d7a28c9f!2sThe%20Woodlands%20Office%20Park!5e0!3m2!1sen!2sza!4v1715845076128!5m2!1sen!2sza" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <!-- Alternative Image (if needed) -->
                <!-- <img class="img-fluid image" src="assets/images/map/office.png" (click)="openMap()" alt="office_location"> -->
              </div>

            </div>
          </div>

    </div>
</div>
<!-- Contact End -->
