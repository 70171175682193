import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'technology-section',
  styleUrls: ['./technology-section.component.css'],
  template: `
    <div class="techsection component-background">
      <div *ngIf="isMobileOrTablet">
      <h1>Our Technology</h1>
        <icons-carousel/>
      </div>
      <div *ngIf="!isMobileOrTablet" class="tech-content">
      <img
  loading="lazy"
  [src]="image"
  alt="Our Technology"
  class="full-screen-image"
  (load)="onImageLoad()" (error)="onImageLoad()"
/>
      </div>
      <div class="section">
        <div class="content-section">
          <p>
          AfricaWeather is a technology-driven leader in the IoT space, going beyond traditional forecasting to transform weather data into actionable insights that help businesses manage risk, optimize operations, and make smarter decisions. With our advanced weather intelligence, "powered by our own Total Lightning Network and partnerships with South African Weather Services and global providers, we deliver real-time data across 15+ industries. Our solutions protect assets, streamline workflows, and uncover opportunities, driving efficiency and resilience in a data-driven world.
          </p>
        </div>
      </div>
      <div class="content-button">
        <a routerLink="/contact" class="learn-more-btn">LEARN MORE</a>
      </div>
    </div>
  `,
})
export class TechnologySectionComponent implements OnInit {
  isMobileOrTablet: boolean | undefined;
  @Input('image') image:string | undefined;
  isLoading: boolean = false;

  constructor() {}

  ngOnInit() {
    this.isMobileOrTablet = window.innerWidth <= 768; // Adjust the width as needed for your breakpoints
  }
  onImageLoad() {
    this.isLoading = false;
  }
}
