<div class="container-fluid py-4 wow fadeInUp" data-wow-delay="0.1s">
    <div class="container py-5">
        <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px;">
            <h5 class="fw-bold text-primary text-uppercase">{{title ||'Additional Web Services'}}</h5>
        </div>
        <div class="row g-0">
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                <div class="bg-light rounded">
                    <div class="border-bottom py-4 px-5 mb-4">
                        <h4 class="text-primary mb-1">Free Plan</h4>
                    </div>
                    <div class="p-5 pt-0">
                        <h1 class="display-5 mb-3">
                            <small class="align-top" style="font-size: 22px; line-height: 45px;">R</small>00.00<small
                                class="align-bottom" style="font-size: 16px; line-height: 40px;">/ Month</small>
                        </h1>
                        <div class="d-flex justify-content-between mb-3"><span>National storm tracking map</span><i class="fa fa-check text-primary pt-1"></i></div>
                       <hr>
                        <div class="d-flex justify-content-between mb-3"><span>National radar map & animation <br />
                            Provincial storm tracking animation <br/>
                            Provincial overview, temperature, rain & wind maps<br/>
                            Provincial storm tracking & lightning maps</span><i class="fa fa-times text-danger pt-1"></i></div>
                       <hr> <div class="d-flex justify-content-between mb-3"><span>Composite, fog risk & advanced temperature graphs <br/>
                            Provincial radar & lightning maps & animations</span><i class="fa fa-times text-danger pt-1"></i></div>
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                <div class="bg-white rounded shadow position-relative" style="z-index: 1;">
                    <div class="border-bottom py-4 px-5 mb-4">
                        <h4 class="text-primary mb-1">Starter Plan</h4>
                    </div>
                    <div class="p-5 pt-0">
                        <h1 class="display-5 mb-3">
                            <small class="align-top" style="font-size: 22px; line-height: 45px;">R</small>39.99<small
                                class="align-bottom" style="font-size: 16px; line-height: 40px;">/ Month</small>
                        </h1>
                        <div class="d-flex justify-content-between mb-3"><span>National storm tracking map</span><i class="fa fa-check text-primary pt-1"></i></div>
                        <hr><div class="d-flex justify-content-between mb-3"><span>National radar map & animation <br />
                            Provincial storm tracking animation <br/>
                            Provincial overview, temperature, rain & wind maps <br/>
                            Provincial storm tracking & lightning maps </span><i class="fa fa-check text-primary pt-1"></i></div> <hr>
                        <div class="d-flex justify-content-between mb-3"><span>Composite, fog risk & advanced temperature graphs <br/>
                            Provincial radar & lightning maps & animations</span><i class="fa fa-times text-danger pt-1"></i></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                <div class="bg-light rounded">
                    <div class="border-bottom py-4 px-5 mb-4">
                        <h4 class="text-primary mb-1">Enthusiast Plan</h4>
                    </div>
                    <div class="p-5 pt-0">
                        <h1 class="display-5 mb-3">
                            <small class="align-top" style="font-size: 22px; line-height: 45px;">R</small>179.99<small
                                class="align-bottom" style="font-size: 16px; line-height: 40px;">/ Month</small>
                        </h1>
                        <div class="d-flex justify-content-between mb-3"><span>National storm tracking map</span><i class="fa fa-check text-primary pt-1"></i></div><hr>
                        <div class="d-flex justify-content-between mb-3"><span>National radar map & animation <br />
                            Provincial storm tracking animation <br/>
                            Provincial overview, temperature, rain & wind maps <br/>
                            Provincial storm tracking & lightning maps</span><i class="fa fa-check text-primary pt-1"></i></div> <hr>
                        <div class="d-flex justify-content-between mb-3"><span>Composite, fog risk & advanced temperature graphs <br/>
                            Provincial radar & lightning maps & animations</span><i class="fa fa-check text-primary pt-1"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div style="text-align: center;margin-top:20px;font-size: 19px;">
    <a [href]="register_url" target="_blank" class="btn btn-primary">Click here to Register</a>	
</div>