import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
    selector: 'industry-righ-card',
    template: `
    <div class="content">
        <img
          [src]="image"
          [alt]="title"
          class="content-image"
        />
        <div class="content-text">
          <h2>{{title}}</h2>
          <ng-content></ng-content>
        </div>
      </div>
    `,
    styleUrls:['./industry.css']
})

export class IndustryRightCardComponent{
@Input('title') title:string| undefined;
@Input('image') image:string| undefined;
@Input('description') description:string | undefined;
constructor(private renderer: Renderer2) {}

}
