<div class="container py-5">
  <h3 class="text-center">Some of our clients and partners</h3>
  <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let slide of images">
      <ng-template class="row" carouselSlide [id]="slide.id">
        <div class="column">
          <div class="card">
            <img [src]="slide.src" [alt]="slide.alt" [title]="slide.title" alt="Card Image" class="card-image" />
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>