import { Component } from '@angular/core';

@Component({
  selector: 'app-our-tech',
  templateUrl: './our-tech.component.html',
  styleUrls: ['./our-tech.component.css']
})
export class OurTechComponent {

}
