import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'into-header',
  template: `

<section class="hero-section">
        <div class="hero-content">
            <h1 class="header">Transforming weather data into actionable insights</h1>
            <p>At AfricaWeather, we can't control the weather, but we help you manage it and protect your business from its risks.</p>
            <button class="btn" routerLink="/about">Learn more</button>
        </div>
    </section>
  `,
  styleUrls:['./styles.component.css']
})

export class IntroHeaderComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
