import { Component } from '@angular/core';

export interface slideInterfaces {
  image:string;
  type:string;
  title:string;
  description:string;
  url:string;
}
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselsComponent {

  slides:slideInterfaces[]=[
    {
     image:"assets/images/mobilecover.png",type:"Mobile Application",title:"Try our mobile application",description:"",url:"/products/application"
    },
    {
      image:"assets/images/products/stations1.jpg",type:"Products",title:"Weather Stations",description:"AfricaWeather offers unique turnkey weather solutions to the power, mining, construction, aviation, agriculture and logistics industries.",url:"/products/stations"
     },
     {
      image:"assets/images/products/logistic1.jpg",type:"Industries",title:"AfricaWeather Railguard",description:"Track conditions are influenced by ambient conditions and the environmental impact of weather can lead to unsafe field conditions for rail operators.",url:"/industries/railguards"
     }
  ]

}
