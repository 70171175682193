import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'header',
  template: `
  <div class="container-fluid bg-primary py-5 bg-header-golf main-container header-container" style="background-image: url('{{background_image_path}}');">
    <div class="row py-5">
        <div class="col-12 pt-lg-5 mt-lg-5">
            <h1 class="display-4 text-white animated zoomIn">{{title}}</h1>
            <p class="h5 text-white">{{description}}</p>
        </div>
    </div>
</div>
  `,
  styleUrls:['./header.component.css']
})

export class HeaderComponent {
  @Input('title') title:string='';
  @Input('description') description ='';
  @Input('background_image_path') background_image_path='';

}
