import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mega-nav',
  template: `
  <nav class="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
  <div class="logo">
      <img src="logo.png" alt="Logo">
  </div>
  <ul class="nav-links">
      <li class="mega-dropdown">
          <a href="#products">PRODUCTS & SERVICES</a>
          <div class="mega-menu">
              <div class="mega-menu-column">
                  <h3>Weather Solutions</h3>
                  <ul>
                      <li><a href="#warnings">Warnings</a></li>
                      <li><a href="#accuclimate">AccuClimate</a></li>
                      <li><a href="#forecasting">Forecasting</a></li>
                      <li><a href="#forensic">Forensic Services</a></li>
                  </ul>
              </div>
              <div class="mega-menu-column">
                  <h3>Broadcast Solutions</h3>
                  <ul>
                      <li><a href="#connect">AccuWeather Connect</a></li>
                      <li><a href="#suite">Data Suite</a></li>
                      <li><a href="#api">API Services</a></li>
                  </ul>
              </div>
              <div class="mega-menu-column featured">
                  <img src="feature.jpg" alt="Featured">
                  <h3>DATA DRIVES BUSINESS</h3>
                  <p>Weather data drives business intel and success.</p>
                  <a href="#sample" class="feature-button">Get Free Sample</a>
              </div>
          </div>
      </li>
      <li><a href="#why">WHY ACCUWEATHER</a></li>
      <li><a href="#events">WEATHER EVENTS</a></li>
      <li><a href="#support">LEARN & SUPPORT</a></li>
      <li><a href="#contact">CONTACT US</a></li>
      <!-- <li><a href="#started" class="cta-button">LET'S GET STARTED</a></li> -->
  </ul>
  <!-- <button class="search-icon">
      <i class="fas fa-search"></i>
  </button> -->
</nav>

  `,
  styleUrls: ['./mega-nav.component.css'],
})
export class MegaNavComponent { }
