import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: 'Industry-card',
  template: `
<dynamic-heading title="Industry Applications" level="h4" />
<div class="applications-container">
    <div class="application-card" *ngFor="let industry of industries">
        <img [src]="industry.imagePath" [alt]="industry.title" class="card-image">
        <div class="card-content">
            <dynamic-heading [title]="industry.title" level="h6" textColor="text-secondary-color" />
            <p class="card-description">{{industry.description}}</p>
        </div>
    </div>
  `,
  styles: [`

        .applications-container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 20px;
        }

        .application-card {
            flex: 1;
            min-width: 250px;
            border-radius: 10px;
            overflow: hidden;
        }

        .card-image {
            width: 100%;
            height: 180px;
            object-fit: cover;
        }

        .card-content {
            padding: 15px;
            text-align: center;
        }

        .card-title {
            font-size: 20px;
            margin-bottom: 10px;
            font-weight: bold;
            text-align: center;
        }

        .card-description {
            font-size: 15px;
            line-height: 1.4;
        }



        @media (max-width: 768px) {
            .applications-container {
                flex-direction: column;
            }
        }

  `]
})

export class IndustryCardComponent implements OnInit {
  @Input() title: string = '';
@Input() industries: any[] = [];

  constructor() { }

  ngOnInit() {
    console.log('IndustryCardComponent initialized with title:', this.title);
  }
}
