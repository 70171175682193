import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'our-tect',
  template: `
    <div class="our-tech-container">
      <div class="tech-content">
      <img src="assets/images/icons/our-tech/OUR TECT.png" alt="Our Tectnology" class="full-screen-image" loading="lazy">
      </div>
    </div>
  `,
  styles: [
    `
    .our-tech-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding:20px;
    }

    .tech-content{
      padding-top:40px;
      padding-bottom:40px;
    }

    .full-screen-image {
      max-width: 100%;
      max-height: 100%;
    }
    `
  ]
})

export class OurTechComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
