import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MainCardData } from 'src/app/shared/interfaces/cards/main-card.interface';

@Component({
  selector: 'main-card',
  template: `
    <section
      class="hero-section"
      [ngStyle]="{ 'background-image': 'url(' + info.backgroundImage + ')' }"
    >
      <div class="hero-content">
        <dynamic-heading
          [title]="info.headerText"
          level="h1"
          textColor="text-white-color"
        />
        <p>{{ info.descriptionText }}</p>
        <button class="btn" [routerLink]="info.buttonLink">
          {{ info.buttonText }}
        </button>
      </div>
    </section>
  `,
  styleUrls: ['./main-card.component.css'],
})
export class MainCardComponent {
  @Input('info') info!: MainCardData;
}
